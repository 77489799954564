import { FC } from "react";
import React from "react";
import { SnackbarMenu } from "../../../models/types";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout/ColumnLayout";
import { StaticInformation } from "@mittwald/flow-components/dist/components/StaticInformation/StaticInformation";
import { NumberField } from "@mittwald/flow-components/dist/components/NumberField";
import { TextArea } from "@mittwald/flow-components/dist/components/TextArea";

interface AdminMenuFieldsProps {
  menu: SnackbarMenu;
  weekdayKey: "monday" | "wednesday";
}
export const AdminMenuFields: FC<AdminMenuFieldsProps> = (props) => {
  const { menu, weekdayKey } = props;
  const variantKey = menu.variant;
  const total = menu.bookingAmount + menu.buffer;
  return (
    <ColumnLayout key={menu.menuId} medium={[1, 1]}>
      <TextArea
        _height={50}
        label={variantKey}
        name={weekdayKey + variantKey}
        rules={{ required: true }}
        value={menu.description}
      />
      <StaticInformation
        label={`${variantKey}Bookings`}
        text={{ text: menu.bookingAmount.toString() }}
      />
      <NumberField
        label={`${variantKey}Buffer`}
        name={weekdayKey + variantKey + "Buffer"}
        rules={{ required: true }}
        value={menu.buffer}
      />
      <StaticInformation
        label={`${variantKey}Total`}
        text={{ text: total.toString() }}
      />
    </ColumnLayout>
  );
};
