import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import { BacksideRenderer } from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import { faUtensils } from "@fortawesome/pro-regular-svg-icons/faUtensils";
import { RedirectToLink } from "@mittwald/flow-lib/dist/components/RedirectToLink";
import React from "react";
import externalMenuDashboard from "./menu/ExternalMenuDashboard";
import ExternalErrorView from "../components/Errors/ExternalErrorView";

export const menuPages = PageTree.build(BacksideRenderer, {
  "/menu": {
    views: {
      error: ExternalErrorView,
      main: () => <RedirectToLink linkId={"externalDashboard"} />,
    },
  },
  "/menu/external": {
    icon: faUtensils,
    linkId: "externalDashboard",
    views: {
      error: ExternalErrorView,
      main: externalMenuDashboard,
    },
  },
});
