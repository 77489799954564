import { getArticleCategoryIcon } from "../../../lib/icon";
import { getProxiedArticleImage } from "../../../lib/image";
import { SnackbarArticle } from "../../../models/types";
import React, { FC } from "react";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { useForm } from "@mittwald/flow-components/dist/components/Form/hooks/useForm/useForm";
import { DateTime } from "luxon";
import { snackbarApi } from "../../../api/SnackbarApiClient";
import { showSnackbar } from "@mittwald/flow-components/dist/components/Snackbar/lib";
import { Snackbar } from "@mittwald/flow-components/dist/components/Snackbar/Snackbar";
import { Modal } from "@mittwald/flow-components/dist/components/Modal/Modal";
import Form from "@mittwald/flow-components/dist/components/Form/Form";
import { ModalContent } from "@mittwald/flow-components/dist/components/Modal/components/ModalContent/ModalContent";
import { SectionItem } from "@mittwald/flow-components/dist/components/Section/components/SectionItem";
import { Headline } from "@mittwald/flow-components/dist/components/Tile/styled/Headline";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout/ColumnLayout";
import ImageBox from "@mittwald/flow-components/dist/components/ImageBox";
import { StaticInformation } from "@mittwald/flow-components/dist/components/StaticInformation/StaticInformation";
import { MoneyValue } from "@mittwald/flow-components/dist/components/MoneyValue/MoneyValue";
import { DatePicker } from "@mittwald/flow-components/dist/components/DatePicker/DatePicker";
import { ModalFooter } from "@mittwald/flow-components/dist/components/Modal/components/ModalFooter/ModalFooter";
import { NumberField } from "@mittwald/flow-components/dist/components/NumberField";
import { SectionLayout } from "@mittwald/flow-components/dist/components/Section/components";

export interface SnackbarConfirmationModalProps {
  article: SnackbarArticle;
}

export const SnackbarOrderConfirmationModal: FC<
  SnackbarConfirmationModalProps
> = (props: SnackbarConfirmationModalProps) => {
  const { article } = props;
  const visibilityController = useVisibilityController(false);
  const form = useForm<{ orderDate: string; amount: number }>({
    defaultValues: {
      orderDate: DateTime.now().toString(),
    },
    onSubmit: async (values) => {
      const result = await snackbarApi.createOrder.request({
        requestBody: {
          articleId: article.articleId,
          amount: values.amount,
          orderDate:
            DateTime.fromISO(values.orderDate).toISO({
              includeOffset: false,
            }) ?? undefined,
        },
      });
      if (result.status === 201) {
        showSnackbar(<Snackbar text={"orderSuccessful"} />);
      } else if (result.status === 416) {
        showSnackbar(<Snackbar error text={"orderFailedOrderLimitReached"} />);
      } else {
        showSnackbar(<Snackbar error text={"orderFailed"} />);
      }
      visibilityController.hide();
    },
  });

  return (
    <Modal visibility={visibilityController}>
      <ModalContent
        description="orderDescription"
        headline={{
          id: "orderSnackbar",
          values: {
            article: article.name,
          },
        }}
        headlineIcon={getArticleCategoryIcon(article.category)}
      >
        <Form controller={form}>
          <SectionLayout>
            <SectionItem>
              <Headline text={article.name} />
              <ColumnLayout medium={[1, 1]}>
                <ImageBox
                  _borderRadius="circle"
                  _width={100}
                  url={getProxiedArticleImage(article)}
                />
                <StaticInformation label="price">
                  <MoneyValue
                    currency="EUR"
                    locale="de-DE"
                    value={article.centPrice / 100}
                  />
                </StaticInformation>
                <NumberField
                  label="amount"
                  name="amount"
                  rules={{
                    required: true,
                    maxLength: 2,
                    min: 1,
                    max: 20,
                  }}
                  value={1}
                />
                <DatePicker
                  clearable={false}
                  label="orderDate"
                  name="orderDate"
                  rules={{ required: true }}
                />
              </ColumnLayout>
            </SectionItem>
          </SectionLayout>
        </Form>
      </ModalContent>
      <ModalFooter
        primary={{
          text: "orderNow",
          action: { form },
          ok: true,
        }}
        visibility={visibilityController}
      />
    </Modal>
  );
};
