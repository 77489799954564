import { color } from "@mittwald/flow-styles/dist/tokens/ts/variables";
import styled from "styled-components";

export const SidebarContentContainer = styled.div`
  color: ${color.font.text.inverse.default};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  box-sizing: border-box;
  padding: 32px 156px 32px 32px;
  justify-content: space-between;
  position: relative;
  height: 100vh;
`;
