import { ResourceListColumnRenderer } from "@mittwald/flow-components/dist/components/ResourceList";
import { SnackbarOrder } from "../../models/types";
import { Column } from "@mittwald/flow-components/dist/components/ResourceList/components/Column/Column";
import { faBox } from "@fortawesome/pro-regular-svg-icons/faBox";
import React from "react";
import { faCancel } from "@fortawesome/pro-regular-svg-icons/faCancel";

export const orderAmountColumnRenderer: ResourceListColumnRenderer<
  SnackbarOrder
> = ({ resource }) => {
  const amount = resource.amount;
  if (resource.cancelled) {
    return (
      <Column
        icon={faCancel}
        text={{ text: amount.toString() }}
        tooltip={{ text: "Buchung wurde storniert" }}
      />
    );
  }
  return <Column icon={faBox} text={{ text: amount.toString() }} />;
};
