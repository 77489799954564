import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import { BacksideRenderer } from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import accountingHistory from "./accounting/AccountingHistory";
import UserAdminOverview from "./accounting/UserAdminOverview";
import { RedirectToLink } from "@mittwald/flow-lib/dist/components/RedirectToLink";
import HeaderNavigation from "./users/HeaderNavigation";
import React from "react";
import { faUser } from "@fortawesome/pro-regular-svg-icons/faUser";
import { faReceipt } from "@fortawesome/pro-regular-svg-icons/faReceipt";
import ErrorView from "../components/Errors/ErrorView";
import OrderList from "../models/OrderList";
import iconInvoice from "@mittwald/flow-icons/dist/invoice";

export const accountingPages = PageTree.build(BacksideRenderer, {
  "/accounting": {
    views: {
      main: () => {
        return <RedirectToLink linkId="accountingHistory" />;
      },
      headerNavigation: () => <HeaderNavigation />,
    },
    linkId: "accounting",
  },

  "/accounting/history": {
    icon: faReceipt,
    linkId: "accountingHistory",
    props: {
      ctaPages: [
        {
          action: () => OrderList.createExport(),
          title: "exportAllOrders",
          icon: iconInvoice,
        },
      ],
    },
    views: {
      error: ErrorView,
      main: accountingHistory,
    },
  },

  "/accounting/employee": {
    icon: faUser,
    linkId: "accountingEmployee",
    views: {
      error: ErrorView,
      main: UserAdminOverview,
    },
  },
});
