import { ResourceListColumnRenderer } from "@mittwald/flow-components/dist/components/ResourceList";
import { SnackbarMenu } from "../../models/types";
import React from "react";
import Text from "@mittwald/flow-components/dist/components/Text/Text";

export const menuNameColumnRenderer: ResourceListColumnRenderer<
  SnackbarMenu
> = ({ resource }) => {
  return (
    <Text
      i18n={{
        text: resource.description,
      }}
    />
  );
};
