import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import register from "./login/Register";
import login from "./login/Login";
import ForgotPassword from "./login/ForgotPassword";
import { ResetPassword } from "./login/ResetPassword";
import { Button } from "@mittwald/flow-components/dist/components/Button/Button";
import React from "react";
import LoginSidebar from "../components/Login/LoginSidebar";
import { FrontsideRenderer } from "../renderer/FrontsideRenderer";
import EmailVerify from "./login/EmailVerify";

export const loginPages = PageTree.build(FrontsideRenderer, {
  "/login": {
    views: {
      main: login,
      sidebar: LoginSidebar,
      callToAction: () => {
        return <Button action="/register" ok text="register" />;
      },
    },
    linkId: "login",
  },
  "/register": {
    views: {
      sidebar: LoginSidebar,
      main: register,
      callToAction: () => {
        return <Button action="/login" ok text="login" />;
      },
    },
    linkId: "register",
  },
  "/passwordForgot": {
    views: {
      sidebar: LoginSidebar,
      main: ForgotPassword,
    },
    linkId: "passwordForgot",
  },
  "/passwordReset": {
    views: {
      sidebar: LoginSidebar,
      main: ResetPassword,
    },
    linkId: "passwordReset",
  },
  "/verifyEmail": {
    views: {
      sidebar: LoginSidebar,
      main: EmailVerify,
    },
    linkId: "verifyEmail",
  },
});
