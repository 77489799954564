import { RedirectToLink } from "@mittwald/flow-lib/dist/components/RedirectToLink";
import { useLinkBuilder } from "@mittwald/flow-lib/dist/hooks/useLinkBuilder";
import React, { FC } from "react";
import { useCheckAuthenticated } from "../../hooks/useCheckAuthenticated";
import useAutoLogoutOnExpiredSession from "../../hooks/useAutoLogoutOnExpiredSession";
import { IllustratedMessage } from "@mittwald/flow-react-components/IllustratedMessage";
import Icon from "@mittwald/flow-react-components/Icon";
import { IconHandStop } from "@tabler/icons-react";
import Heading from "@mittwald/flow-react-components/Heading";
import Text from "@mittwald/flow-react-components/Text";
import { Action } from "@mittwald/flow-react-components/Action";
import { Button } from "@mittwald/flow-react-components/Button";
import { useNavigate } from "react-router-dom";

export const NoAccess: FC = () => {
  const linkBuilder = useLinkBuilder();
  const navigate = useNavigate();
  const authenticated = useCheckAuthenticated();

  useAutoLogoutOnExpiredSession();

  return (
    <>
      {authenticated ? (
        <IllustratedMessage color="negative">
          <Icon>
            <IconHandStop />
          </Icon>
          <Heading>Halt!</Heading>
          <Text>Du hast auf diesen Bereich keinen Zugriff!</Text>
          <Action action={() => navigate(linkBuilder("lunch"))}>
            <Button color="primary">Zurück zum Mittagessen</Button>
          </Action>
        </IllustratedMessage>
      ) : (
        <RedirectToLink linkId="login" />
      )}
    </>
  );
};

export default NoAccess;
