import { Box } from "@mittwald/flow-components/dist/components/Box";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink";
import { useCompactMode } from "@mittwald/flow-components/dist/hooks/useCompactMode";
import { spacing } from "@mittwald/flow-styles/dist/tokens/ts/variables";
import React, { FC } from "react";
import { imprintLink, privacyLink } from "../config";
import { buildRendererTranslationKey } from "../i18n/buildRendererTranslationKey";

export const FooterLinks: FC = () => {
  const compactMode = useCompactMode();

  if (compactMode) {
    return (
      <Box
        _bgColor="default"
        _flexDirection="row"
        _itemGap="l"
        _justifyContent="center"
        _mt="auto"
        _p="m"
      >
        <TextLink
          action={privacyLink}
          i18n={buildRendererTranslationKey("privacy")}
        />
        <TextLink
          action={imprintLink}
          i18n={buildRendererTranslationKey("imprint")}
        />
      </Box>
    );
  }

  return (
    <Box
      _bottom={spacing.base.s}
      _flexDirection="row"
      _itemGap="m"
      _justifyContent="center"
      _mt="auto"
      _noShrink
      _p="xl"
      _pt="l"
    >
      <TextLink
        action={privacyLink}
        i18n={buildRendererTranslationKey("privacy")}
      />
      <TextLink
        action={imprintLink}
        i18n={buildRendererTranslationKey("imprint")}
      />
    </Box>
  );
};

export default FooterLinks;
