import { SnackbarArticle } from "../../models/types";
import { faMoneyBill } from "@fortawesome/pro-regular-svg-icons/faMoneyBill";
import Column from "@mittwald/flow-components/dist/components/ResourceList/components/Column/Column";
import { MoneyValue } from "@mittwald/flow-components/dist/components/MoneyValue/MoneyValue";
import React from "react";
import { ResourceListColumnRenderer } from "@mittwald/flow-components/dist/components/ResourceList";

export const priceColumnRenderer: ResourceListColumnRenderer<
  SnackbarArticle
> = ({ resource }) => {
  return (
    <Column
      icon={faMoneyBill}
      text={
        <MoneyValue value={resource.centPrice ? resource.centPrice / 100 : 0} />
      }
      tooltip={"centPrice"}
    />
  );
};
