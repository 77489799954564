import styled from "styled-components";
import { Heading } from "@mittwald/flow-react-components/Heading";

export const ModalBadgeHeading = styled.span`
  display: flex;
  align-items: center;
  gap: var(--heading--icon-to-text-spacing);
`;

export default ModalBadgeHeading;
