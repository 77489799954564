import React, { FC, useEffect, useState } from "react";
import { UserProfileNavigation } from "@mittwald/flow-renderer/dist/components/BacksideRenderer/components/UserProfileNavigation";
import { NavigationItem } from "@mittwald/flow-components/dist/components/Navigation/components/NavigationItem/NavigationItem";
import sessionStore from "../../login/session";
import { AnyAction } from "@mittwald/flow-components/dist/lib/actions/types";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer/libs";
import UserSettingsModal from "./modals/UserSettingsModal";
import { faPowerOff } from "@fortawesome/pro-regular-svg-icons/faPowerOff";
import { useCompactMode } from "@mittwald/flow-components/dist/hooks/useCompactMode/useCompactMode";
import { Box } from "@mittwald/flow-components/dist/components/Box/Box";
import { Avatar } from "@mittwald/flow-components/dist/components/Avatar/Avatar";
import { H2 } from "@mittwald/flow-components/dist/components/H2/H2";
import { snackbarApi } from "../../api/SnackbarApiClient";
import { faDownload, faSliders } from "@fortawesome/pro-regular-svg-icons";
import AccountingDownloadFilesModal from "../accounting/modals/AccountingDownloadFilesModal";
import { faComment } from "@fortawesome/pro-regular-svg-icons/faComment";
import { faGear } from "@fortawesome/pro-regular-svg-icons/faGear";
import { useRendererContext } from "@mittwald/flow-renderer/dist/context/rendererContext";
import { faHistory } from "@fortawesome/pro-regular-svg-icons/faHistory";
import { Icon } from "@mittwald/flow-components/dist/components/Icon";

export const HeaderNavigation: FC = () => {
  const compactMode = useCompactMode();

  const { navigationMenuVisibility } = useRendererContext();

  const userOrderLimitResource = snackbarApi.getUserOrderLimit.getResource({});
  const employeeId = sessionStore.employeeId;
  const avatarImageUrl = `${
    import.meta.env.VITE_APP_API_URI
  }/images/public/gopher.jpg`;

  const logoutAction: AnyAction = [sessionStore.logout];

  const updateSettingsAction = [
    () =>
      showModal(
        "userSettings",
        <UserSettingsModal
          onLimitSuccessfullyChanged={() => {
            userOrderLimitResource.expire();
          }}
        />,
      ),
    navigationMenuVisibility.hide,
  ];

  const downloadFileAction = [
    () => showModal("downloadFile", <AccountingDownloadFilesModal />),
    navigationMenuVisibility.hide,
  ];

  const historyAction = [
    { link: { id: "history" } },
    navigationMenuVisibility.hide,
  ];

  const adminAction = [
    { link: { id: "admin" } },
    navigationMenuVisibility.hide,
  ];

  const [role, setRole] = useState<string | undefined>(undefined);
  useEffect(() => {
    snackbarApi.getUserRole
      .request({})
      .then((response) => {
        if (response.status == 200) {
          setRole(response.content.role);
        }
      })
      .catch(() => {
        setRole("employee");
      });
  }, []);

  const feedbackAction: AnyAction = [
    () =>
      window.open(
        "https://mittwald.slack.com/archives/C06BAD4862C",
        "_blank",
        "noreferrer",
      ),
    navigationMenuVisibility.hide,
  ];

  const isUserAccounting = role == "accounting";
  const isUserAdmin = role == "admin";
  if (compactMode) {
    return (
      <Box _flexDirection="column" _itemGap="m">
        {isUserAccounting && (
          <NavigationItem
            action={downloadFileAction}
            icon={faDownload}
            inverse
            title="navigation.downloadFile"
          />
        )}
        {isUserAdmin && (
          <NavigationItem
            action={adminAction}
            icon={faGear}
            inverse
            title="navigation.admin"
          />
        )}
        <NavigationItem
          action={historyAction}
          icon={faHistory}
          inverse
          title="history"
        />
        <NavigationItem
          action={updateSettingsAction}
          icon={faSliders}
          inverse
          title="navigation.changeSettings"
        />
        <NavigationItem
          action={"https://mittwald.slack.com/archives/C06BAD4862C"}
          icon={faComment}
          inverse
          title={"feedbackLink"}
        />
        <Box _flexDirection="row" _itemGap="s">
          <Avatar _noShrink _size="m" url={avatarImageUrl} />
          <H2 _alignSelf="center" _color="inverse" ellipsis>
            {employeeId}
          </H2>
          <Icon
            _alignSelf="center"
            _color="inverse"
            _ml={{ raw: "auto" }}
            _noShrink
            _size="m"
            action={logoutAction}
            icon={faPowerOff}
          />
        </Box>
      </Box>
    );
  }
  return (
    <UserProfileNavigation
      _ml="auto"
      avatar={{
        url: avatarImageUrl,
      }}
      email="Schön, dass du da bist!"
      name={{ text: employeeId }}
    >
      <NavigationItem
        action={updateSettingsAction}
        icon={faSliders}
        title="changeSettings"
      />
      <NavigationItem action={logoutAction} icon={faPowerOff} title="logout" />
      {isUserAccounting && (
        <NavigationItem
          action={downloadFileAction}
          icon={faDownload}
          title="downloadFile"
        />
      )}
      <NavigationItem
        action={feedbackAction}
        icon={faComment}
        title={"feedbackLink"}
      />
    </UserProfileNavigation>
  );
};

export default HeaderNavigation;
