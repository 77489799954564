import { EditInputs } from "../EditInputs";
import { snackbarApi } from "../../../api/SnackbarApiClient";
import React, { FC, useState } from "react";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { useForm } from "@mittwald/flow-components/dist/components/Form/hooks/useForm/useForm";
import { showSnackbar } from "@mittwald/flow-components/dist/components/Snackbar/lib";
import Snackbar from "@mittwald/flow-components/dist/components/Snackbar/Snackbar";
import Modal from "@mittwald/flow-components/dist/components/Modal";
import { ModalContent } from "@mittwald/flow-components/dist/components/Modal/components/ModalContent/ModalContent";
import { ModalFooter } from "@mittwald/flow-components/dist/components/Modal/components/ModalFooter/ModalFooter";
import { SectionItem } from "@mittwald/flow-components/dist/components/Section/components/SectionItem";
import { TextField } from "@mittwald/flow-components/dist/components/TextField/TextField";
import Select from "@mittwald/flow-components/dist/components/Select";
import Form from "@mittwald/flow-components/dist/components/Form";
import { faToolbox } from "@fortawesome/pro-regular-svg-icons/faToolbox";
import { Switch } from "@mittwald/flow-components/dist/components/Switch/Switch";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout/ColumnLayout";
import { Banner } from "@mittwald/flow-components/dist/components/Banner/Banner";
import { SectionLayout } from "@mittwald/flow-components/dist/components/Section/components";

export const CreateNewArticleModal: FC = () => {
  const [value, setValue] = useState<"snack" | "drink">("snack");
  const visibilityController = useVisibilityController(false);

  const articleResource = snackbarApi.getAllArticles.getResource({
    query: { includeDisabled: true },
  });

  const form = useForm<EditInputs>({
    reValidateMode: "onChange",
    mode: "onChange",
    onSubmit: async (values) => {
      const response = await snackbarApi.createArticle.request({
        requestBody: {
          articleId: values.articleId,
          name: values.name,
          centPrice: values.centPrice,
          category: value,
          imageUrl: values.imageUrl,
          enabled: values.enabled,
        },
      });
      if (response.status === 201) {
        showSnackbar(
          <Snackbar
            text={{
              text: "Artikel erfolgreich gespeichert!",
            }}
          />,
        );
      } else {
        showSnackbar(
          <Snackbar
            error
            text={{
              text: "Artikel konnte nicht erstellt werden",
            }}
          />,
        );
        return false;
      }
      articleResource.expire();
      visibilityController.hide();
    },
  });

  return (
    <Modal visibility={visibilityController}>
      <ModalContent
        description="addNewArticleDescription"
        headline={{
          id: "addNewArticle",
        }}
        headlineIcon={faToolbox}
      >
        <Form controller={form}>
          <SectionLayout>
            <SectionItem
              headline="createArticleHeadline"
              headlineContent={
                <Switch
                  defaultChecked={true}
                  label="articleDisabled"
                  labelActive="articleEnabled"
                  name="enabled"
                  textRight={true}
                  value={true}
                />
              }
            >
              <Banner
                description="articleIdBannerDescription"
                headline="articleIdBanner"
                warning={true}
              />
              <ColumnLayout medium={[1, 1]} small={[1]}>
                <TextField
                  label="name"
                  name="name"
                  placeholder={{ text: "Schokoriegel" }}
                  rules={{ required: true }}
                />
                <TextField
                  label="articleId"
                  name="articleId"
                  placeholder={{ text: "schoko" }}
                  rules={{
                    required: true,
                    validate: {
                      isValidArticleId: (v: string) => {
                        const regex = /^[0-9a-z_\-äöü]+$/;
                        if (v == "") {
                          return true;
                        }
                        return regex.test(v);
                      },
                    },
                  }}
                />
              </ColumnLayout>
              <Select.View
                label="category"
                options={[
                  { label: "snack", value: "snack" },
                  { label: "drink", value: "drink" },
                ]}
                placeholder={{ text: "Wähle die Kategorie..." }}
                value={value}
                onChange={setValue}
              />
              <TextField
                label="centPrice"
                name="centPrice"
                placeholder={{ text: "500" }}
                rules={{
                  required: true,
                  validate: {
                    isNumber: (v?) => {
                      const regex = /^(0|[1-9]\d*)(\.\d+)?$/;
                      if (v == "" || v == undefined) {
                        return true;
                      }
                      return regex.test(v);
                    },
                  },
                  min: 1,
                  maxLength: 4,
                }}
              />
              <TextField
                label="imageUrl"
                name="imageUrl"
                placeholder={"newArticleImageUrl"}
                rules={{
                  required: false,
                  minLength: 0,
                  validate: {
                    isUrl: (v?: string) => {
                      const regex = /(https):\/\/[^\s/$.?#].[^\s]*/;
                      if (v == "" || v == undefined) {
                        return true;
                      }
                      return regex.test(v);
                    },
                  },
                }}
              />
            </SectionItem>
          </SectionLayout>
        </Form>
      </ModalContent>
      <ModalFooter
        primary={{
          text: "saveNow",
          action: { form },
        }}
        visibility={visibilityController}
      />
    </Modal>
  );
};
