import { SnackbarOrder } from "../../../models/types";
import React, { FC } from "react";
import Modal from "@mittwald/flow-react-components/Modal";
import Content from "@mittwald/flow-react-components/Content";
import Label from "@mittwald/flow-react-components/Label";
import Button from "@mittwald/flow-react-components/Button";
import Heading from "@mittwald/flow-react-components/Heading";
import Action from "@mittwald/flow-react-components/Action";
import { DateTime } from "luxon";
import { IconToolsKitchen2 } from "@tabler/icons-react";
import Icon from "@mittwald/flow-react-components/Icon";
import { LabeledValue } from "@mittwald/flow-react-components/LabeledValue";
import ColumnLayout from "@mittwald/flow-react-components/ColumnLayout";
import { StatusBadge } from "@mittwald/flow-react-components/StatusBadge";
import { Section } from "@mittwald/flow-react-components/Section";
import Header from "@mittwald/flow-react-components/Header";
import { OverlayController } from "@mittwald/flow-react-components/controller";
import { snackbarApi } from "../../../api/SnackbarApiClient";
import toast from "react-hot-toast";
import { useOverlayController } from "@mittwald/flow-react-components/controller";
import BadgeGroup from "../components/BadgeGroup";
import Select, { Option } from "@mittwald/flow-react-components/Select";
import ActionGroup from "@mittwald/flow-react-components/ActionGroup";
import { Actions } from "@mittwald/flow-components/dist/components/ErrorBoundary/components";
import ModalBadgeHeading from "../components/ModalBadgeHeading";
import ModalBadgeHeadingContent from "../components/ModalBadgeHeadingContent";

interface HistoryOrderDetailModalProps {
  order: SnackbarOrder;
  modalStateController: OverlayController;
  onSuccessfulSubmit: () => void;
}

export const HistoryOrderDetailModal: FC<HistoryOrderDetailModalProps> = (
  props,
) => {
  const { order, modalStateController, onSuccessfulSubmit } = props;

  const [cancellationReason, setCancellationReason] = React.useState<
    "SICK" | "EMPTY" | undefined
  >(undefined);

  const deleteOrder = async () => {
    const response = await snackbarApi.cancelOrder.request({
      path: {
        orderId: order.orderId,
      },
      requestBody: {
        cancellationReason,
      },
    });
    if (response.status === 200) {
      toast.success("Die Buchung wurde erfolgreich storniert");
      onSuccessfulSubmit();
    } else {
      toast.error("Es ist ein Fehler aufgetreten");
    }
  };

  const confirmDialogController = useOverlayController("Modal");

  return (
    <>
      <Modal controller={modalStateController} size="m">
        <Section>
          <Header>
            <ModalBadgeHeading>
              <ModalBadgeHeadingContent>
                <Icon>
                  <IconToolsKitchen2 />
                </Icon>{" "}
                Buchungsdetails
              </ModalBadgeHeadingContent>
              <BadgeGroup>
                {order.isAutomaticallyBooked && (
                  <StatusBadge status="info">Automatisch erstellt</StatusBadge>
                )}
                {order.cancelled && (
                  <StatusBadge status="danger">Buchung storniert</StatusBadge>
                )}
                {order.bookingDate && (
                  <StatusBadge status="success">
                    Abgerechnet am{" "}
                    {DateTime.fromISO(order.bookingDate).toFormat("dd.MM.yyyy")}
                  </StatusBadge>
                )}
              </BadgeGroup>
            </ModalBadgeHeading>
          </Header>
          <ColumnLayout>
            <LabeledValue>
              <Label>Artikelname</Label>
              <Content>{order.article.name}</Content>
            </LabeledValue>
            <LabeledValue>
              <Label>Buchung erstellt am</Label>
              <Content>
                {DateTime.fromISO(order.date).toFormat("dd.MM.yyyy")}
              </Content>
            </LabeledValue>
            <LabeledValue>
              <Label>Buchung für</Label>
              <Content>
                {DateTime.fromISO(order.orderDate).toFormat("dd.MM.yyyy")}
              </Content>
            </LabeledValue>
            <LabeledValue>
              <Label>Gesamtpreis der Buchung</Label>
              <Content>
                {order.amount} x{" "}
                {(order.totalCentPrice / 100 / order.amount).toFixed(2)} &euro;
                = <b>{(order.totalCentPrice / 100).toFixed(2)} &euro;</b>
              </Content>
            </LabeledValue>
          </ColumnLayout>
        </Section>
        <ActionGroup>
          <Action closeOverlay={modalStateController}>
            <Button variant="soft" color="secondary">
              Schließen
            </Button>
            {!order.cancelled && order.article.category === "lunch" && (
              <Action action={confirmDialogController.open}>
                <Button
                  variant="plain"
                  color="danger"
                  isDisabled={!!order.bookingDate}
                >
                  Buchung stornieren
                </Button>
              </Action>
            )}
          </Action>
        </ActionGroup>
      </Modal>
      <CancelOrderConfirmDialog
        onConfirm={deleteOrder}
        order={order}
        controller={confirmDialogController}
        cancellationReasonAction={setCancellationReason}
      />
    </>
  );
};

interface ConfirmDialogProps {
  onConfirm: () => void;
  order: SnackbarOrder;
  controller: OverlayController;
  cancellationReasonAction: (value: "SICK" | "EMPTY") => void;
}

const CancelOrderConfirmDialog: FC<ConfirmDialogProps> = ({
  onConfirm,
  order,
  controller,
  cancellationReasonAction,
}) => {
  return (
    <Modal controller={controller}>
      <Heading>Buchung stornieren</Heading>
      <Content>
        Das Stornieren einer Buchung kann nicht rückgängig gemacht werden und
        eine stornierte Buchung wird dir weiterhin in der Übersicht angezeigt
        werden. Möchtest du dennoch fortfahren?
        {order.cancellationReasonRequired && (
          <Select
            isRequired
            onChange={(value) =>
              cancellationReasonAction(value as "SICK" | "EMPTY")
            }
          >
            <Label>Aus welchem Grund stornierst du?</Label>
            <Option value="SICK">Ich war krank</Option>
            <Option value="EMPTY">Das Essen war leer</Option>
          </Select>
        )}
      </Content>
      <ActionGroup>
        <Action closeOverlay={controller}>
          <Action action={onConfirm}>
            <Button color="danger">Unwiderruflich stornieren</Button>
          </Action>
          <Button color="secondary" variant="soft">
            Abbrechen
          </Button>
        </Action>
      </ActionGroup>
    </Modal>
  );
};
