import { types } from "mobx-state-tree";
import history from "../history";

export const model = types
  .model({
    accessToken: types.maybe(types.string),
    employeeId: types.maybe(types.string),
    email: types.maybe(types.string),
  })
  .actions((self) => {
    function login(token: string, employeeId: string): void {
      if (token != "") {
        logout(false);
        self.accessToken = token;
        self.employeeId = employeeId;
      }
    }
    function logout(redirect: boolean = true): void {
      self.accessToken = undefined;
      self.employeeId = undefined;
      if (redirect) {
        history.push("/");
      }
    }

    function register(employeeId: string, email: string): void {
      logout(false);
      self.email = email;
      self.employeeId = employeeId;
    }

    return {
      login,
      logout,
      register,
    };
  });
export const sessionStore = model.create({});

export default sessionStore;
