import styled from "styled-components";
import { Heading } from "@mittwald/flow-react-components/Heading";

export const ModalBadgeHeading = styled(Heading)`
  width: 100%;
  justify-content: space-between;
  align-items: start;
`;

export default ModalBadgeHeading;
