import { sessionStore } from "../login/session";
import { snackbarApi } from "../api/SnackbarApiClient";

export const useCheckAuthenticated = (failOnApiError = true): boolean => {
  const accessToken = sessionStore.accessToken;
  if (!accessToken) {
    return false;
  }
  const checkApiToken = snackbarApi.verifyToken
    .getResource({ requestBody: { jwt: accessToken } })
    .useWatchData({ optional: true, throwOnError: failOnApiError });
  return !!checkApiToken;
};
