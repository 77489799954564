import React, { FC, useEffect } from "react";
import { snackbarApi } from "../../api/SnackbarApiClient";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer/libs";
import { LunchOrderConfirmationModal } from "./modals/LunchOrderConfirmationModal";
import { Page } from "@mittwald/flow-renderer/dist/components/Page";
import { useQueryArticleId } from "../../hooks/useQueryArticleId";
import { PageMainSection } from "@mittwald/flow-renderer/dist/components/PageMainSection";
import ColumnLayout from "@mittwald/flow-components/dist/components/ColumnLayout/ColumnLayout";
import TranslationProvider from "@mittwald/flow-components/dist/components/TranslationProvider";
import { LunchDayView } from "./components/LunchDayView";
import { BookNowBanner } from "./components/BookNowBanner";
import { IllustratedMessage } from "@mittwald/flow-react-components/IllustratedMessage";
import { IconToolsKitchen2 } from "@tabler/icons-react";
import { Heading } from "@mittwald/flow-react-components/Heading";
import Text from "@mittwald/flow-react-components/Text";
import Icon from "@mittwald/flow-react-components/Icon";

interface LunchDashboardProps {
  category: string;
}

export const LunchDashboard: FC<LunchDashboardProps> = (props) => {
  const { category } = props;

  const articleResource = snackbarApi.getAllArticles.getResource({
    query: { category: category },
  });
  const articleId = useQueryArticleId();

  const menuResource = snackbarApi.getMenus.getResource({
    query: {
      onlyBookable: false,
      variant: snackbarApi.getMenuSelections,
      bookable: snackbarApi.getMenuSelections,
    },
  });

  const articleResponse = articleResource.useWatchData();
  const articles = articleResponse.articles;

  const menuResponse = menuResource.useWatchData();
  const menus = menuResponse.menus;

  useEffect(() => {
    articleResource.expire();
  }, [category]);

  useEffect(() => {
    if (articleId) {
      const article = articles.find(
        (article) => article.articleId === articleId,
      );
      if (article) {
        showModal(
          "OrderConfirmation",
          <LunchOrderConfirmationModal article={article} />,
        );
      }
    }
  }, []);

  const bookingsResource = snackbarApi.getBookings.getResource({});
  const bookings = bookingsResource.useWatchData().bookings;

  return (
    <Page>
      <PageMainSection>
        <BookNowBanner articles={articles} bookings={bookings} />
      </PageMainSection>
      {menus.length < 1 ? (
        <PageMainSection>
          <IllustratedMessage>
            <Icon>
              <IconToolsKitchen2 />
            </Icon>
            <Heading>Hier wird gerade alles vorbereitet</Heading>
            <Text>
              Schneiders bereitet gerade das nächste Mittagsmenü für Euch vor.
            </Text>
          </IllustratedMessage>
        </PageMainSection>
      ) : (
        <TranslationProvider name="menuSelection" type="section">
          <ColumnLayout large={[1, 1]}>
            <LunchDayView
              bookingsResource={bookingsResource}
              menus={menus.slice(0, 2)}
            />
            <LunchDayView
              bookingsResource={bookingsResource}
              menus={menus.slice(2, 4)}
            />
          </ColumnLayout>
        </TranslationProvider>
      )}
    </Page>
  );
};

export default LunchDashboard;
