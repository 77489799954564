import { snackbarApi } from "../../api/SnackbarApiClient";
import { MoneyValue } from "@mittwald/flow-components/dist/components/MoneyValue/MoneyValue";
import { ColumnLayoutContainer } from "@mittwald/flow-components/dist/components/ColumnLayoutContainer";
import { H1 } from "@mittwald/flow-components/dist/components/H1";
import React, { FC } from "react";

interface OrderStatsProps {
  period: "month" | "lastMonth";
}

export const OrderStats: FC<OrderStatsProps> = (props) => {
  const { period } = props;
  const orderStatsResponse = snackbarApi.getUserOrderStats
    .getResource({ path: { period: period } })
    .useWatchData();
  const moneySpent = orderStatsResponse.totalSpend || 0;

  let label;
  if (period == "month") {
    label = "Diesen Monat ausgegeben";
  } else {
    label = "Letzten Monat ausgegeben";
  }

  return (
    <ColumnLayoutContainer>
      <H1 i18n={{ text: <MoneyValue value={moneySpent / 100} /> }} />
      {label}
    </ColumnLayoutContainer>
  );
};
