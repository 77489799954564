import persistStore from "@mittwald/flow-lib/dist/store/persist";
import sessionStore from "./login/session";

export const setup = (): void => {
  const snackbarPersist = persistStore.withDefaults({
    namespace: "snackbar",
    applyExternalChanges: true,
  });
  snackbarPersist(sessionStore, "session");
};
