import React, { FC, useEffect } from "react";
import { Page } from "@mittwald/flow-renderer/dist/components/Page";
import { useResourceList } from "@mittwald/flow-components/dist/components/ResourceList/hooks/useResourceList/useResourceList";
import { ResourceList } from "@mittwald/flow-components/dist/components/ResourceList/components/ResourceList/ResourceList";
import { SnackbarOrder } from "../../models/types";
import { DateTime } from "luxon";
import { snackbarApi } from "../../api/SnackbarApiClient";
import { orderDateColumnRenderer } from "../../components/Order/orderDateColumnRenderer";
import { orderCreatedDateColumnRenderer } from "../../components/Order/orderCreatedDateColumnRenderer";
import { faReceipt } from "@fortawesome/pro-regular-svg-icons/faReceipt";
import { orderPriceColumnRenderer } from "../../components/Order/orderPriceColumnRenderer";
import { orderAmountColumnRenderer } from "../../components/Order/orderAmountColumnRenderer";
import { orderFilter } from "../../components/Order/orderFilter";
import { employeeNameColumnRenderer } from "../../components/Order/employeeNameColumnRenderer";
import { orderNameColumnRenderer } from "../../components/Order/orderNameColumnRenderer";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer/libs";
import { OrderDetailModal } from "./modals/OrderDetailModal";
import IllustratedMessage from "@mittwald/flow-react-components/IllustratedMessage";
import Icon from "@mittwald/flow-react-components/Icon";
import { IconHistory } from "@tabler/icons-react";
import Heading from "@mittwald/flow-react-components/Heading";
import Text from "@mittwald/flow-react-components/Text";

export const AccountingHistory: FC = () => {
  const orderResource = snackbarApi.getOrdersOfEmployee.getResource({});
  const orderResponse = orderResource.useWatchData();
  const orders = orderResponse.orders;

  useEffect(() => {
    orderResource.expire();
  }, []);

  const resourceList = useResourceList<SnackbarOrder>({
    resources: orders,
    nameColumn: orderNameColumnRenderer,
    defaultSortingRule: {
      id: "createdDate",
      desc: true,
    },
    mainActionMapper: (order) => () => {
      showModal("orderDetailOverviewModal", <OrderDetailModal order={order} />);
    },
    columnAccessors: [
      {
        id: "createdDate",
        accessor: (order: SnackbarOrder) =>
          DateTime.fromISO(order.date).toMillis(),
      },
      {
        id: "orderDate",
        accessor: (order: SnackbarOrder) =>
          DateTime.fromISO(order.orderDate).toMillis(),
      },
      {
        id: "price",
        accessor: (order: SnackbarOrder) =>
          order.article.centPrice ? order.article.centPrice : 0,
      },
    ],
    bodyColumns: [
      {
        id: "employeeId",
        renderer: employeeNameColumnRenderer,
      },
      {
        id: "createdDate",
        renderer: orderCreatedDateColumnRenderer,
      },
      {
        id: "orderDate",
        renderer: orderDateColumnRenderer,
      },
      {
        id: "price",
        renderer: orderPriceColumnRenderer,
      },
      {
        id: "amount",
        renderer: orderAmountColumnRenderer,
      },
    ],
    secondaryStatusIcons: [
      {
        icon: faReceipt,
        mapper: (order) => {
          const date = order.bookingDate
            ? DateTime.fromISO(order.bookingDate)
            : undefined;
          const status = date ? "enabled" : "disabled";
          const tooltip = date
            ? `Abgebucht am ${date.toFormat("dd.MM.yyyy")}`
            : "Noch nicht abgebucht";

          return {
            tooltip: { text: tooltip },
            status: status,
          };
        },
      },
    ],
    filter: orderFilter,
    booleanFilters: [
      "createdThisMonth",
      "orderedForThisMonth",
      "notBookedOrdersOnly",
      "isCancelled",
      "isOrdered",
      "articleIsLunch",
      "articleIsSnack",
      "articleIsDrink",
    ],
  });

  return (
    <Page>
      {orders.length < 1 ? (
        <IllustratedMessage>
          <Icon>
            <IconHistory />
          </Icon>
          <Heading>Keine Buchungen</Heading>
          <Text>
            Für diesen Zeitraum konnten keine Buchungen, die über das snaxus
            abgewickelt wurden, gefunden werden.
          </Text>
        </IllustratedMessage>
      ) : (
        <ResourceList
          {...resourceList}
          columnLayout={{
            body: [
              { key: "employeeId", maxWidth: 100, responsivePriority: 4 },
              { key: "createdDate", responsivePriority: 2 },
              { key: "orderDate" },
              { key: "price", responsivePriority: 3 },
              { key: "amount", maxWidth: 50, responsivePriority: 1 },
            ],
          }}
          hideTilesView
        />
      )}
    </Page>
  );
};

export default AccountingHistory;
