import React, { FC, useEffect } from "react";
import { Page } from "@mittwald/flow-renderer/dist/components/Page";
import { snackbarApi } from "../../api/SnackbarApiClient";
import { getArticleCategoryDefaultImage } from "../../lib/image";
import { SnackbarArticle } from "../../models/types";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer/libs";
import { SnackbarOrderConfirmationModal } from "./modals/SnackbarOrderConfirmationModal";
import { OrderLimitReachedBanner } from "../../components/OrderLimitReachedBanner";
import { ArticleResourceList } from "./components/ArticleResourceList";
import { MainActionMapper } from "@mittwald/flow-components/dist/components/ResourceList";
import { useOrderLimit } from "../../hooks/useOrderLimit";
import { IconBackhoe } from "@tabler/icons-react";
import { IllustratedMessage } from "@mittwald/flow-react-components/IllustratedMessage";
import { Heading } from "@mittwald/flow-react-components/Heading";
import Text from "@mittwald/flow-react-components/Text";
import Icon from "@mittwald/flow-react-components/Icon";

interface SnackbarDashboardProps {
  category: "lunch" | "snack" | "drink";
}

export const SnackbarDashboard: FC<SnackbarDashboardProps> = (props) => {
  const { category } = props;
  const orderLimit = useOrderLimit();
  const articleResource = snackbarApi.getAllArticles.getResource({
    query: { category: category },
  });

  const articleResponse = articleResource.useWatchData();
  const articles = articleResponse.articles;

  useEffect(() => {
    articleResource.expire();
  }, [category]);

  const mainAction: MainActionMapper<SnackbarArticle> = (article) => () => {
    showModal(
      "SnackbarOrderConfirmation",
      <SnackbarOrderConfirmationModal article={article} />,
    );
  };
  const defaultImage = getArticleCategoryDefaultImage(props.category);
  return (
    <Page>
      <OrderLimitReachedBanner orderLimit={orderLimit} />
      {articles.length < 1 ? (
        <IllustratedMessage>
          <Icon>
            <IconBackhoe />
          </Icon>
          <Heading>Hier wird gearbeitet</Heading>
          <Text>
            Aktuell sind noch keine Artikel dieser Kategorie über das snaxus
            buchbar.
          </Text>
          <Text>Nutzt solange bitte weiterhin die Listen.</Text>
        </IllustratedMessage>
      ) : (
        <ArticleResourceList
          articles={articles}
          defaultImage={defaultImage}
          mainAction={mainAction}
        />
      )}
    </Page>
  );
};

export default SnackbarDashboard;
