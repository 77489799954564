import React, { FC } from "react";
import * as Styled from "../styled";
import { SocialProps } from "../types";

export const Social: FC<SocialProps> = (props) => {
  const { className } = props;
  return (
    <Styled.SocialContainer className={className}></Styled.SocialContainer>
  );
};
