import styled from "styled-components";

export const BadgeGroup = styled.div`
  display: flex;
  gap: var(--size-px--xs);
  // Center vertically
  height: 100%;
  align-items: center;
`;

export default BadgeGroup;
