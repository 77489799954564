import { spacing } from "@mittwald/flow-styles/dist/tokens/ts/variables";
import styled from "styled-components";

export const SocialContainer = styled.div`
  display: flex;

  & > * {
    min-width: 0;
  }

  & > *:not(:last-child) {
    margin-right: ${spacing.base.m};
  }

  & > a {
    line-height: 1;
  }
`;
