import { ResourceListColumnRenderer } from "@mittwald/flow-components/dist/components/ResourceList";
import { SnackbarOrder } from "../../models/types";
import { Column } from "@mittwald/flow-components/dist/components/ResourceList/components/Column/Column";
import { faUser } from "@fortawesome/pro-regular-svg-icons/faUser";
import React from "react";

export const employeeNameColumnRenderer: ResourceListColumnRenderer<
  SnackbarOrder
> = ({ resource }) => {
  return (
    <Column
      icon={faUser}
      text={{
        text: resource.employeeId,
      }}
      tooltip={"employeeId"}
    />
  );
};
