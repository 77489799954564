import { ResourceListColumnRenderer } from "@mittwald/flow-components/dist/components/ResourceList";
import { SnackbarOrder } from "../../models/types";
import { DateTime } from "luxon";
import { Column } from "@mittwald/flow-components/dist/components/ResourceList/components/Column/Column";
import { faHistory } from "@fortawesome/pro-regular-svg-icons/faHistory";
import { DateTimeText } from "@mittwald/flow-components/dist/components/DateTimeText/DateTimeText";
import React from "react";
import { faCancel } from "@fortawesome/pro-regular-svg-icons/faCancel";

export const orderCreatedDateColumnRenderer: ResourceListColumnRenderer<
  SnackbarOrder
> = ({ resource }) => {
  const date = resource.date ? DateTime.fromISO(resource.date) : undefined;
  const tooltip = date
    ? `Buchung erstellt am ${date.toFormat("dd.MM.yyyy 'um' HH:mm 'Uhr'")}`
    : undefined;
  if (resource.cancelled) {
    return (
      <Column
        icon={faCancel}
        text={<DateTimeText format="date" value={resource.date} />}
        tooltip={{ text: "Buchung wurde storniert" }}
      />
    );
  }
  return (
    <Column
      icon={faHistory}
      text={<DateTimeText format="date" value={resource.date} />}
      tooltip={{ text: tooltip }}
    />
  );
};
