import {
  Box,
  boxPropsType,
} from "@mittwald/flow-components/dist/components/Box";
import { MittwaldIcon } from "@mittwald/flow-components/dist/components/MittwaldIcon";
import { MittwaldWave } from "@mittwald/flow-components/dist/components/MittwaldWave";
import { OuterLayoutMixinProps } from "@mittwald/flow-components/dist/mixins/outerLayout";
import { ChildrenProps } from "@mittwald/flow-lib/dist/types/ChildrenProps";
import React, { FC } from "react";
import * as Styled from "../styled";
import { Social } from "./Social";
import { useOpenDevMenuWithTimeout } from "@mittwald/flow-renderer/dist/hooks/useOpenDevMenuWithTimeout";

type SidebarProps = OuterLayoutMixinProps & ChildrenProps;

export const Sidebar: FC<SidebarProps> = (props) => {
  const { ref } = useOpenDevMenuWithTimeout();
  const { children, ...restProps } = props;

  const boxProps = boxPropsType.pickFrom(restProps);

  return (
    <Box {...boxProps}>
      <MittwaldWave
        _height="100%"
        _left={0}
        _position="absolute"
        _top={0}
        _width="100%"
        vertical
      />
      <Styled.SidebarContentContainer>
        <Styled.SidebarHeaderContainer>
          <MittwaldIcon _color="inverse" _size={32} ref={ref} />
        </Styled.SidebarHeaderContainer>
        <Box _mb={128}>
          <Styled.SidebarViewSlotContainer>
            {children}
          </Styled.SidebarViewSlotContainer>
        </Box>
        <Styled.SidebarFooterContainer>
          <Social />
        </Styled.SidebarFooterContainer>
      </Styled.SidebarContentContainer>
    </Box>
  );
};

export default Sidebar;
