import { ResourceListColumnRenderer } from "@mittwald/flow-components/dist/components/ResourceList";
import { SnackbarOrder } from "../../models/types";
import { Column } from "@mittwald/flow-components/dist/components/ResourceList/components/Column/Column";
import { faMoneyBill } from "@fortawesome/pro-regular-svg-icons/faMoneyBill";
import MoneyValue from "@mittwald/flow-components/dist/components/MoneyValue/MoneyValue";
import React from "react";
import { faCancel } from "@fortawesome/pro-regular-svg-icons/faCancel";

export const orderPriceColumnRenderer: ResourceListColumnRenderer<
  SnackbarOrder
> = ({ resource }) => {
  const totalCentPrice = resource.totalCentPrice;
  if (resource.cancelled) {
    return (
      <Column
        icon={faCancel}
        text={<MoneyValue value={totalCentPrice / 100} />}
        tooltip={{ text: "Buchung wurde storniert" }}
      />
    );
  }
  return (
    <Column
      icon={faMoneyBill}
      text={<MoneyValue value={totalCentPrice / 100} />}
    />
  );
};
