import { FC } from "react";
import React from "react";
import { SectionItem } from "@mittwald/flow-components/dist/components/Section/components/SectionItem";
import { DateTime } from "luxon";
import { DateTimeText } from "@mittwald/flow-components/dist/components/DateTimeText/DateTimeText";
import Switch from "@mittwald/flow-components/dist/components/Switch/Switch";
import { useFormContext } from "@mittwald/flow-components/dist/components/Form";
import { SnackbarMenuSelection } from "../../../models/types";
import { AdminMenuFields } from "./AdminMenuFields";
import { HeavyListItem } from "@mittwald/flow-components/dist/components/List/components";
import { AdminOverviewInputs } from "../MenuAdminOverview";

interface AdminMenuSectionProps {
  date: DateTime;
  weekdayKey: "monday" | "wednesday";
  selection: SnackbarMenuSelection;
}
export const AdminMenuSection: FC<AdminMenuSectionProps> = (props) => {
  const { date, weekdayKey, selection } = props;
  const form = useFormContext<AdminOverviewInputs>();

  const isMenuActive =
    selection.menus.filter((menu) => {
      return menu.active;
    }).length > 1;

  const formWatchKey =
    weekdayKey === "monday" ? "mondayInactive" : "wednesdayInactive";
  const showFields = form.watch(formWatchKey, isMenuActive);

  const menuFields = selection.menus.map((menu) => {
    return (
      <HeavyListItem key={menu.menuId}>
        <AdminMenuFields menu={menu} weekdayKey={weekdayKey} />
      </HeavyListItem>
    );
  });
  return (
    <SectionItem
      headline={{
        text: (
          <DateTimeText
            format="date"
            translationKey={weekdayKey}
            value={date}
          />
        ),
      }}
      headlineContent={
        <Switch
          label="switch.noMenu"
          labelActive="switch.menu"
          name={weekdayKey + "Inactive"}
          value={isMenuActive}
        />
      }
    >
      {showFields && menuFields}
    </SectionItem>
  );
};
