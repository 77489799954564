import React, { FC } from "react";
import { SnackbarMenu } from "../../../models/types";
import { useResourceList } from "@mittwald/flow-components/dist/components/ResourceList/hooks/useResourceList/useResourceList";
import { menuNameColumnRenderer } from "../../../components/Menu/menuNameColumnRenderer";
import { menuDateColumnRenderer } from "../../../components/Menu/menuDateColumnRenderer";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer/libs";
import { LunchBookingConfirmationModal } from "../modals/LunchBookingConfirmationModal";
import { ResourceList } from "@mittwald/flow-components/dist/components/ResourceList/components/ResourceList/ResourceList";
import { snackbarApi } from "../../../api/SnackbarApiClient";
import { ResourceRowState } from "@mittwald/flow-components/dist/components/ResourceList/hooks/useResourceList/types";

interface MenuResourceListProps {
  menus: SnackbarMenu[];
  onMenuSuccessfullyBooked: () => void;
}
export const MenuResourceList: FC<MenuResourceListProps> = (props) => {
  const { menus, onMenuSuccessfullyBooked } = props;
  const bookedMenus = snackbarApi.getBookings
    .getResource({})
    .useWatchData({ throwOnError: false })?.bookings;

  const baseUrl = import.meta.env.VITE_APP_API_URI;
  const menuResourceList = useResourceList<SnackbarMenu>({
    imageUrl: (menu) => ({
      initials: menu.variant,
      url: `${baseUrl}/images/public/${menu.variant}.jpg`,
    }),
    resources: menus,
    nameColumn: menuNameColumnRenderer,
    bodyColumns: [
      {
        id: "date",
        renderer: menuDateColumnRenderer,
      },
    ],
    resourceState: (menu) => {
      if (bookedMenus?.find((booking) => booking.menuId === menu.menuId)) {
        return ResourceRowState.ACTIVE;
      } else if (!menu.isMenuBookingPossible) {
        return ResourceRowState.DISABLED;
      } else {
        return ResourceRowState.DEFAULT;
      }
    },
    primaryStatusIcon: (menu) => {
      if (bookedMenus?.find((booking) => booking.menuId === menu.menuId)) {
        return {
          type: "success",
          tooltip: {
            id: "alreadyBookedThisMenu",
            values: {
              text: "isBooked",
            },
          },
        };
      }
    },
    mainActionMapper: (menus) => () => {
      showModal(
        "BookingConfirmation",
        <LunchBookingConfirmationModal
          menu={menus}
          onMenuSuccessfullyBooked={onMenuSuccessfullyBooked}
        />,
      );
    },
  });

  return (
    <ResourceList
      defaultView={"tiles"}
      {...menuResourceList}
      columnLayout={{ name: { minWidth: 280 }, body: [{ key: "date" }] }}
      hideListView
      hideSorting
    />
  );
};
