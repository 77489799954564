import React, { FC, useState, useEffect } from "react";
import TextField from "@mittwald/flow-components/dist/components/TextField";
import { ModalContent } from "@mittwald/flow-components/dist/components/Modal/components/ModalContent/ModalContent";
import { ModalFooter } from "@mittwald/flow-components/dist/components/Modal/components/ModalFooter/ModalFooter";
import { SectionItem } from "@mittwald/flow-components/dist/components/Section/components/SectionItem";
import Modal from "@mittwald/flow-components/dist/components/Modal";
import StaticInformation from "@mittwald/flow-components/dist/components/StaticInformation";
import sessionStore from "../../../login/session";
import Form from "@mittwald/flow-components/dist/components/Form/Form";
import { useForm } from "@mittwald/flow-components/dist/components/Form/hooks/useForm/useForm";
import { snackbarApi } from "../../../api/SnackbarApiClient";
import { UserSettingsInputs } from "../../login/LoginInputs";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { showSnackbar } from "@mittwald/flow-components/dist/components/Snackbar/lib";
import { Snackbar } from "@mittwald/flow-components/dist/components/Snackbar/Snackbar";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout/ColumnLayout";
import {
  faSliders,
  faKey,
  faMoneyBills,
} from "@fortawesome/pro-regular-svg-icons";
import { SectionLayout } from "@mittwald/flow-components/dist/components/Section/components";
import { NumberField } from "@mittwald/flow-components/dist/components/NumberField";
import { FeatureSwitch } from "../../../components/FeatureSwitch";
import { Text } from "@mittwald/flow-components/dist/components/Text";
import { useOrderLimit } from "../../../hooks/useOrderLimit";

export interface SuccessfullyChangedCallback {
  onLimitSuccessfullyChanged?: () => void;
}
export interface UserSettingsModalProps extends SuccessfullyChangedCallback {}

export const UserSettingsModal: FC<UserSettingsModalProps> = (
  props: UserSettingsModalProps,
) => {
  const confirmationModalVisibility = useVisibilityController(false);
  const employeeId = sessionStore.employeeId;

  const orderLimit = useOrderLimit();
  let personalOrderLimit = orderLimit.orderLimit;

  const [role, setRole] = useState<string | undefined>(undefined);
  useEffect(() => {
    snackbarApi.getUserRole
      .request({})
      .then((response) => {
        if (response.status == 200) {
          setRole(response.content.role);
        }
      })
      .catch(() => {
        setRole("employee");
      });
  }, []);

  const emailResponse = snackbarApi.getUserRole.getResource({}).useWatchData();
  const email = emailResponse.email;
  const isUserAccounting = role == "accounting";

  let isOrderLimitActive: boolean = false;
  let orderLimitPlaceholder: number | undefined = 50;
  isOrderLimitActive = personalOrderLimit != -1;
  if (personalOrderLimit != -1) {
    orderLimitPlaceholder = personalOrderLimit;
  }

  const form = useForm<UserSettingsInputs>({
    onSubmit: async (values) => {
      if (values.oldPassword != undefined || values.newPassword != undefined) {
        if (
          (values.oldPassword !== undefined &&
            values.newPassword === undefined) ||
          (values.newPassword !== undefined && values.oldPassword === undefined)
        ) {
          return showSnackbar(
            <Snackbar
              error
              text={{
                text: "Bitte fülle alle Felder zur Passwortänderung aus!",
              }}
            />,
          );
        } else {
          const result = await snackbarApi.updatePassword.request({
            requestBody: {
              employeeId: employeeId,
              oldPassword: values.oldPassword,
              newPassword: values.newPassword,
            },
          });
          if (result.status === 200) {
            showSnackbar(
              <Snackbar
                text={{
                  text: "Passwort erfolgreich geändert",
                }}
              />,
            );
          }
          confirmationModalVisibility.hide();
        }
      }

      if (values.orderLimitactive && values.yourLimit != personalOrderLimit) {
        const resultOrderLimit = await snackbarApi.updateOrderLimit.request({
          requestBody: {
            orderLimit: values.yourLimit,
          },
        });

        if (resultOrderLimit.status === 200) {
          showSnackbar(
            <Snackbar
              text={{
                text: "Bestelllimit erfolgreich geändert",
              }}
            />,
          );
          props.onLimitSuccessfullyChanged?.();
          personalOrderLimit = values.yourLimit;
          confirmationModalVisibility.hide();
        }
      }

      if (!values.orderLimitactive && personalOrderLimit != -1) {
        const resultOrderLimit = await snackbarApi.updateOrderLimit.request({
          requestBody: {
            orderLimit: -1,
          },
        });

        if (resultOrderLimit.status === 200) {
          showSnackbar(
            <Snackbar
              text={{
                text: "Bestelllimit erfolgreich geändert",
              }}
            />,
          );
          props.onLimitSuccessfullyChanged?.();
          personalOrderLimit = values.yourLimit;
          confirmationModalVisibility.hide();
        }
      }
    },
    mode: "onBlur",
    defaultValues: {
      orderLimitactive: isOrderLimitActive,
      yourLimit: orderLimitPlaceholder,
    },
  });

  const [orderLimitActive] = form.watch(["orderLimitactive"]);

  return (
    <Modal visibility={confirmationModalVisibility}>
      <ModalContent
        description="changeSettingsDescription"
        headline="changeSettings"
        headlineIcon={faSliders}
      >
        <Form controller={form}>
          <SectionLayout>
            <ColumnLayout medium={[1, 1]}>
              <StaticInformation
                label="employeeId"
                text={{
                  text: employeeId,
                }}
              />
              <StaticInformation
                label="email"
                text={{
                  text: email,
                }}
              />
            </ColumnLayout>
            {!isUserAccounting && (
              <>
                <SectionItem
                  headline="yourLimit"
                  headlineContent={<FeatureSwitch name="orderLimitactive" />}
                  headlineIcon={faMoneyBills}
                >
                  <Text i18n="personalOrderLimitHintDescription" />
                </SectionItem>
                {orderLimitActive && (
                  <NumberField
                    _mt="m"
                    label="yourLimitLabel"
                    name="yourLimit"
                    rules={{ required: true, min: 1 }}
                    textSuffix={{ text: "€" }}
                    value={personalOrderLimit}
                  />
                )}
              </>
            )}
            <SectionItem headline="changePassword" headlineIcon={faKey}>
              <TextField
                autoComplete="off"
                label="oldPassword"
                name="oldPassword"
                type="password"
              />
              <TextField
                autoComplete="off"
                label="newPassword"
                name="newPassword"
                rules={{ minLength: 12 }}
                type="passwordWithGenerator"
              />
            </SectionItem>
          </SectionLayout>
        </Form>
      </ModalContent>
      <ModalFooter
        primary={{
          text: "save",
          action: { form },
        }}
        visibility={confirmationModalVisibility}
      />
    </Modal>
  );
};

export default UserSettingsModal;
