import { filterFactory } from "@mittwald/flow-components/dist/components/ResourceList/hooks/useResourceList/filterFactory";
import { SnackbarOrder } from "../../models/types";
import { DateTime } from "luxon";

export const orderFilter = filterFactory<SnackbarOrder>()
  .fulltextFilter((order, searchText) => {
    const employeeIdContains = order.employeeId
      .toLowerCase()
      .includes(searchText.toLowerCase().trim());
    const nameIncludes = order.article.name
      .toLowerCase()
      .includes(searchText.toLowerCase().trim());
    return employeeIdContains || nameIncludes;
  })
  .boolFilter("createdThisMonth", (order) => {
    const monthEquals =
      DateTime.fromISO(order.date).month == DateTime.now().month;
    const yearEquals = DateTime.fromISO(order.date).year == DateTime.now().year;
    return monthEquals && yearEquals;
  })
  .boolFilter("orderedForThisMonth", (order) => {
    const monthEquals =
      DateTime.fromISO(order.orderDate).month == DateTime.now().month;
    const yearEquals =
      DateTime.fromISO(order.orderDate).year == DateTime.now().year;
    return monthEquals && yearEquals;
  })
  .boolFilter("notBookedOrdersOnly", (order) => {
    return !order.bookingDate;
  })
  .boolFilter("isOrdered", (order) => {
    return !order.cancelled;
  })
  .boolFilter("isCancelled", (order) => {
    return order.cancelled == true;
  })
  .boolFilter("articleIsLunch", (order) => {
    return order.article.category === "lunch";
  })
  .boolFilter("articleIsSnack", (order) => {
    return order.article.category === "snack";
  })
  .boolFilter("articleIsDrink", (order) => {
    return order.article.category === "drink";
  })
  .get();
