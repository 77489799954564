import React, { FC } from "react";
import { snackbarApi } from "../../../api/SnackbarApiClient";
import { SnackbarMenu } from "../../../models/types";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { Modal } from "@mittwald/flow-components/dist/components/Modal/Modal";
import { ModalContent } from "@mittwald/flow-components/dist/components/Modal/components/ModalContent/ModalContent";
import { ModalFooter } from "@mittwald/flow-components/dist/components/Modal/components/ModalFooter/ModalFooter";
import { showSnackbar } from "@mittwald/flow-components/dist/components/Snackbar/lib";
import { Snackbar } from "@mittwald/flow-components/dist/components/Snackbar/Snackbar";
import { StaticInformation } from "@mittwald/flow-components/dist/components/StaticInformation/StaticInformation";
import { SectionItem } from "@mittwald/flow-components/dist/components/Section/components/SectionItem";
import Form from "@mittwald/flow-components/dist/components/Form/Form";
import { useForm } from "@mittwald/flow-components/dist/components/Form/hooks/useForm/useForm";
import { SectionLayout } from "@mittwald/flow-components/dist/components/Section/components";
import { faUtensils } from "@fortawesome/pro-regular-svg-icons/faUtensils";
import Text from "@mittwald/flow-components/dist/components/Text/Text";
import { DateTimeText } from "@mittwald/flow-components/dist/components/DateTimeText/DateTimeText";
import { DateTime } from "luxon";
import { Banner } from "@mittwald/flow-components/dist/components/Banner/Banner";
import { Pill } from "@mittwald/flow-components/dist/components/Pill";
import { faClock } from "@fortawesome/pro-regular-svg-icons/faClock";
import { I18nTextDefinition } from "@mittwald/flow-components/dist/hooks/useTranslation";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout/ColumnLayout";

export interface ConfirmationModalProps {
  menu: SnackbarMenu;
  onMenuSuccessfullyBooked: () => void;
}
export const LunchBookingConfirmationModal: FC<ConfirmationModalProps> = (
  props: ConfirmationModalProps,
) => {
  const { menu, onMenuSuccessfullyBooked } = props;
  const visibilityController = useVisibilityController(false);
  const existingBookings = snackbarApi.getBookings
    .getResource({})
    .useWatchData();

  const alreadyCreatedBooking = existingBookings.bookings.find((booking) => {
    const bookingDate = DateTime.fromISO(booking.bookedDate).set({
      hour: 12,
      minute: 0,
      millisecond: 0,
      second: 0,
    });
    const menuDate = DateTime.fromISO(menu.date).set({
      hour: 12,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    return bookingDate.toMillis() === menuDate.toMillis();
  });

  const userHasAlreadyBooked = alreadyCreatedBooking != undefined;
  const userHasBookedThisMenu =
    existingBookings.bookings.find((b) => menu.menuId === b.menuId) !=
    undefined;

  const bookingStillPossibleResponse = snackbarApi.getMenuBookable
    .getResource({ query: { menuId: menu.menuId } })
    .useWatchData();

  const showResultSnackbar = (
    i18n: I18nTextDefinition,
    error: boolean = false,
  ): void => {
    showSnackbar(<Snackbar error={error} text={i18n} />);
  };

  const switchMenu = async (): Promise<void> => {
    if (!alreadyCreatedBooking) return;

    const result = await snackbarApi.changeBooking.request({
      path: {
        bookingId: alreadyCreatedBooking.bookingId,
      },
      requestBody: {
        menuId: userHasBookedThisMenu ? undefined : menu.menuId,
      },
    });
    if (result.status === 200) {
      showResultSnackbar("bookingSuccessful");
      onMenuSuccessfullyBooked();
    } else if (result.status === 401) {
      showResultSnackbar("bookingFailedNoAccess", true);
    } else {
      showResultSnackbar("bookingFailed", true);
    }
  };

  const form = useForm<{ menuId: string }>({
    onSubmit: async () => {
      if (userHasAlreadyBooked) {
        if (alreadyCreatedBooking.menuId !== menu.menuId) {
          // SWITCH THE MENU
          await switchMenu();
          return visibilityController.hide();
        } else {
          // CANCEL THE MENU
          const response = await snackbarApi.cancelMenuBooking.request({
            path: {
              bookingId: alreadyCreatedBooking.bookingId,
            },
          });

          if (response.status === 200) {
            showResultSnackbar("bookingSuccessfullyCancelled");
            onMenuSuccessfullyBooked();
          } else if (response.status === 410) {
            showResultSnackbar("bookingCancelNotPossible", true);
          } else {
            showResultSnackbar("bookingNotSuccessfully", true);
          }

          return visibilityController.hide();
        }
      }

      // BOOK THE MENU
      const result = await snackbarApi.preOrderMenu.request({
        requestBody: {
          menuId: menu.menuId,
        },
      });

      if (result.status === 201) {
        showResultSnackbar("bookingSuccessful");
        onMenuSuccessfullyBooked();
      } else if (result.status === 401) {
        showResultSnackbar("bookingFailedNoAccess", true);
      } else if (result.status === 410) {
        showResultSnackbar("bookingFailedTooLate", true);
      } else {
        showResultSnackbar("bookingFailed", true);
      }
      visibilityController.hide();
    },
  });
  const description = userHasAlreadyBooked
    ? userHasBookedThisMenu
      ? "bookingDescriptionAlreadyBookedThisMenu"
      : "bookingDescriptionAlreadyBooked"
    : "bookingDescription";

  const primaryActionText = userHasBookedThisMenu ? "cancelNow" : "orderNow";

  return (
    <Modal visibility={visibilityController}>
      <ModalContent
        description={description}
        headline={{
          id: userHasBookedThisMenu ? "bookLunchAlreadyBooked" : "bookLunch",
          values: {
            menu: menu.description,
          },
        }}
        headlineIcon={faUtensils}
      >
        <Form controller={form}>
          <SectionLayout>
            <SectionItem
              headline={"bookMenuHeadline"}
              headlineContent={
                userHasBookedThisMenu && (
                  <Pill
                    label={{
                      id: "alreadyBookedThisMenu",
                    }}
                    success
                  />
                )
              }
            >
              {!bookingStillPossibleResponse.bookable && (
                <Banner headline={"bookingNotPossibleAnymore"} icon={faClock} />
              )}
              <ColumnLayout medium={[1, 1]}>
                <StaticInformation
                  label={"menuDate"}
                  text={<DateTimeText format={"date"} value={menu.date} />}
                />
                <StaticInformation
                  label={"menuDeadline"}
                  text={
                    <DateTimeText
                      format={"dateTime"}
                      value={bookingStillPossibleResponse.deadline}
                    />
                  }
                />
              </ColumnLayout>
              <StaticInformation label="lunchMenu">
                <Text text={menu.description} />
              </StaticInformation>
            </SectionItem>
          </SectionLayout>
        </Form>
      </ModalContent>
      {userHasBookedThisMenu && bookingStillPossibleResponse.bookable ? (
        <ModalFooter
          primary={{
            text: primaryActionText,
            disabled: !bookingStillPossibleResponse.bookable,
            action: { form },
            ok: !userHasBookedThisMenu,
            destructive: userHasBookedThisMenu,
          }}
          secondary={{
            text: "switchMenu",
            disabled: !bookingStillPossibleResponse.bookable,
            action: async () => {
              await switchMenu();
              visibilityController.hide();
            },
          }}
          visibility={visibilityController}
        />
      ) : (
        <ModalFooter
          primary={{
            text: primaryActionText,
            disabled: !bookingStillPossibleResponse.bookable,
            action: { form },
            ok: !userHasBookedThisMenu,
            destructive: userHasBookedThisMenu,
          }}
          visibility={visibilityController}
        />
      )}
    </Modal>
  );
};
