import { spacing } from "@mittwald/flow-styles/dist/tokens/ts/variables";
import React, { FC } from "react";
import * as Styled from "../styled";
import { CallToActionProps } from "../types";

/**
 * Use this to display a component in the upper right-hand side corner.
 * Container has to set `position: relative`
 *
 * @param CallToActionProps Props
 */
export const CallToAction: FC<CallToActionProps> = ({ children, ...rest }) => {
  return (
    <Styled.ElevatedBox
      _flexDirection="row"
      _justifyContent="flex-end"
      _position="absolute"
      _right={spacing.base.xl}
      _top={spacing.base.xl}
      {...rest}
    >
      {children}
    </Styled.ElevatedBox>
  );
};
