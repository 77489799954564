import React, { FC } from "react";
import { VisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import DefaultModal from "@mittwald/flow-components/dist/components/Modal/DefaultModal";
import Section from "@mittwald/flow-components/dist/components/Section/Section";
import Text from "@mittwald/flow-components/dist/components/Text/Text";
import TranslationProvider from "@mittwald/flow-components/dist/components/TranslationProvider";
import { useFormContext } from "@mittwald/flow-components/dist/components/Form";
import { RegisterInputs } from "./LoginInputs";

interface RegisterConfirmationModalProps {
  visibilityController: VisibilityController;
  employeeId: string;
}

export const RegisterConfirmationModal: FC<RegisterConfirmationModalProps> = (
  props,
) => {
  const { visibilityController, employeeId } = props;
  const form = useFormContext<RegisterInputs>();

  return (
    <DefaultModal
      _size={"s"}
      primary={{
        action: { form },
        text: "confirmRegistrationEmployeeId",
        ok: true,
      }}
      visibility={visibilityController}
    >
      <TranslationProvider name="registerConfirmation" type="modal">
        <Section.Item headline={{ id: "headline" }}>
          <Text i18n={{ id: "checkYourEmployeeId", values: { employeeId } }} />
        </Section.Item>
      </TranslationProvider>
    </DefaultModal>
  );
};

export default RegisterConfirmationModal;
