import { ResourceListColumnRenderer } from "@mittwald/flow-components/dist/components/ResourceList";
import { getArticleCategoryIcon } from "../../lib/icon";
import { SnackbarOrder } from "../../models/types";
import { Column } from "@mittwald/flow-components/dist/components/ResourceList/components/Column/Column";
import React from "react";

export const orderNameColumnRenderer: ResourceListColumnRenderer<
  SnackbarOrder
> = ({ resource }) => {
  return (
    <Column
      icon={getArticleCategoryIcon(resource.article.category)}
      text={{
        text: resource.article.name,
      }}
    />
  );
};
