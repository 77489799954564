import React, { FC } from "react";
import { MoneyValue } from "@mittwald/flow-components/dist/components/MoneyValue/MoneyValue";
import { snackbarApi } from "../../../api/SnackbarApiClient";
import { getArticleCategoryIcon } from "../../../lib/icon";
import { SnackbarArticle } from "../../../models/types";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { Modal } from "@mittwald/flow-components/dist/components/Modal/Modal";
import { ModalContent } from "@mittwald/flow-components/dist/components/Modal/components/ModalContent/ModalContent";
import { ModalFooter } from "@mittwald/flow-components/dist/components/Modal/components/ModalFooter/ModalFooter";
import { showSnackbar } from "@mittwald/flow-components/dist/components/Snackbar/lib";
import { Snackbar } from "@mittwald/flow-components/dist/components/Snackbar/Snackbar";
import { StaticInformation } from "@mittwald/flow-components/dist/components/StaticInformation/StaticInformation";
import { SectionItem } from "@mittwald/flow-components/dist/components/Section/components/SectionItem";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout/ColumnLayout";
import { Headline } from "@mittwald/flow-components/dist/components/Tile/styled/Headline";
import { DatePicker } from "@mittwald/flow-components/dist/components/DatePicker/DatePicker";
import Form from "@mittwald/flow-components/dist/components/Form/Form";
import { DateTime } from "luxon";
import { useForm } from "@mittwald/flow-components/dist/components/Form/hooks/useForm/useForm";
import { NumberField } from "@mittwald/flow-components/dist/components/NumberField";
import { SectionLayout } from "@mittwald/flow-components/dist/components/Section/components";

export interface ConfirmationModalProps {
  article: SnackbarArticle;
}
export const LunchOrderConfirmationModal: FC<ConfirmationModalProps> = (
  props: ConfirmationModalProps,
) => {
  const { article } = props;
  const visibilityController = useVisibilityController(false);

  const getDefaultDate = (): DateTime => {
    const today = DateTime.now();
    let defaultDate = today;
    if (today.weekday != 1 && today.weekday != 3) {
      if (today.weekday > 1) {
        defaultDate = defaultDate.startOf("week");
        if (today.weekday > 3) {
          defaultDate = defaultDate.startOf("week").plus({ day: 2 });
        }
      }
    }
    return defaultDate;
  };

  const form = useForm<{ orderDate: string; amount: number }>({
    defaultValues: {
      orderDate: getDefaultDate().toString(),
    },
    onSubmit: async (values) => {
      const result = await snackbarApi.createOrder.request({
        requestBody: {
          articleId: article.articleId,
          amount: values.amount,
          orderDate:
            DateTime.fromISO(values.orderDate).toISO({
              includeOffset: false,
            }) ?? undefined,
        },
      });
      if (result.status === 201) {
        showSnackbar(<Snackbar text={"orderSuccessful"} />);
      } else if (result.status === 418) {
        showSnackbar(<Snackbar error text={"orderFailedAlreadyOrdered"} />);
      } else if (result.status === 416) {
        showSnackbar(
          <Snackbar error text={"orderFailedMonthlyLimitReached"} />,
        );
      } else if (result.status === 417) {
        showSnackbar(
          <Snackbar error text={"orderFailedRequireToOrderBeforeBook"} />,
        );
      } else {
        showSnackbar(<Snackbar error text={"orderFailed"} />);
      }
      visibilityController.hide();
    },
  });

  return (
    <Modal visibility={visibilityController}>
      <ModalContent
        description="orderDescription"
        headline={{
          id: "orderLunch",
          values: {
            article: article.name,
          },
        }}
        headlineIcon={getArticleCategoryIcon(article.category)}
      >
        <Form controller={form}>
          <SectionLayout>
            <SectionItem>
              <Headline text={article.name} />
              <StaticInformation label="price">
                <MoneyValue
                  currency="EUR"
                  locale="de-DE"
                  value={article.centPrice / 100}
                />
              </StaticInformation>
              <ColumnLayout medium={[1, 1]}>
                <NumberField
                  label="amount"
                  name="amount"
                  rules={{
                    required: true,
                    maxLength: 2,
                    min: 1,
                    max: 20,
                  }}
                  value={1}
                />
                <DatePicker
                  clearable={false}
                  label="orderDate"
                  name="orderDate"
                  rules={{ required: true }}
                  whitelist={(value) =>
                    (value.weekday == 1 &&
                      value.toMillis() <
                        DateTime.now().plus({ days: 7 }).toMillis()) ||
                    (value.weekday == 3 &&
                      value.toMillis() <
                        DateTime.now().plus({ days: 7 }).toMillis())
                  }
                />
              </ColumnLayout>
            </SectionItem>
          </SectionLayout>
        </Form>
      </ModalContent>
      <ModalFooter
        primary={{
          text: "orderNow",
          action: { form },
          ok: true,
        }}
        visibility={visibilityController}
      />
    </Modal>
  );
};
