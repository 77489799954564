import { FC, useEffect } from "react";
import React from "react";
import { Page } from "@mittwald/flow-renderer/dist/components/Page";
import { AdminMenuSection } from "./components/AdminMenuSection";
import { useForm } from "@mittwald/flow-components/dist/components/Form/hooks/useForm/useForm";
import { DateTime } from "luxon";
import { SectionLayout } from "@mittwald/flow-components/dist/components/Section/components";
import { snackbarApi } from "../../api/SnackbarApiClient";
import NoAccessError from "@mittwald/flow-lib/dist/access/NoAccessError";

export interface AdminOverviewInputs {
  mondayInactive: boolean;
  mondaymeat: string;
  mondaymeatBuffer: number;
  mondayveggie: string;
  mondayveggieBuffer: number;
  wednesdayInactive: boolean;
  wednesdaymeat: string;
  wednesdaymeatBuffer: number;
  wednesdayveggie: string;
  wednesdayveggieBuffer: number;
}
export const MenuAdminOverview: FC = () => {
  const userRole = snackbarApi.getUserRole.getResource({}).useWatchData();

  if (userRole.role !== "admin") {
    throw new NoAccessError();
  }

  const menuResource = snackbarApi.getMenuSelections.getResource({});

  const menus = menuResource.useWatchData();

  useEffect(() => {
    menuResource.expire();
  }, []);

  const form = useForm<AdminOverviewInputs>({
    onSubmit: async (values) => {
      await snackbarApi.updateMenu.request({
        requestBody: {
          mondayActive: values.mondayInactive,
          mondayMeat: values.mondaymeat,
          mondayVeggie: values.mondayveggie,
          mondayMeatBuffer: values.mondaymeatBuffer,
          mondayVeggieBuffer: values.mondayveggieBuffer,
          wednesdayActive: values.wednesdayInactive,
          wednesdayMeat: values.wednesdaymeat,
          wednesdayVeggie: values.wednesdayveggie,
          wednesdayMeatBuffer: values.wednesdaymeatBuffer,
          wednesdayVeggieBuffer: values.wednesdayveggieBuffer,
        },
      });
      menuResource.expire();
    },
  });

  return (
    <Page form={form}>
      <SectionLayout>
        {menus.selections.map((selection) => {
          const date = DateTime.fromISO(selection.date);
          return (
            <AdminMenuSection
              date={DateTime.fromISO(selection.date)}
              key={selection.date}
              selection={selection}
              weekdayKey={date.weekday === 1 ? "monday" : "wednesday"}
            />
          );
        })}
      </SectionLayout>
    </Page>
  );
};
