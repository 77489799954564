import { snackbarApi } from "../api/SnackbarApiClient";
import assertStatus from "@mittwald/api-client/dist/types/assertStatus";
import { SnackbarOrder } from "./types";
import { DateTime } from "luxon";

export class OrderList {
  public static async extractData(): Promise<string> {
    const result = await snackbarApi.getOrdersOfEmployee.request({});

    assertStatus(result, 200);

    return this.convertOrdersToCSV(result.content.orders);
  }

  public static createExport = async (): Promise<void> => {
    try {
      const csvContent = await this.extractData();
      console.log(csvContent);

      const csvFile = new File([csvContent], "text/csv");
      const downloadUrl = this.createDownloadUrl(csvFile);
      this.downloadCsvFile(downloadUrl);
    } catch (error) {
      console.error("Failed to create export:", error);
    }
  };

  private static createDownloadUrl(blob: Blob): string {
    return window.URL.createObjectURL(blob);
  }

  private static downloadCsvFile(url: string): void {
    const link = document.createElement("a");
    link.href = url;
    link.download = `buchungsexport-${
      new Date().toISOString().split("T")[0]
    }.csv`;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  private static convertOrdersToCSV(orders: SnackbarOrder[]): string {
    const header =
      "Zieltag,Mitarbeiter,Gesamtpreis,Bestelldatum,Storniert?,Anzahl,Artikel-Name,Kategorie,Buchungsdatum";
    const rows = orders.map((order) => {
      const {
        totalCentPrice,
        date,
        orderDate,
        cancelled,
        amount,
        employeeId,
        article,
        bookingDate,
      } = order;
      const totalEuroPrice = (totalCentPrice / 100).toFixed(2);
      return `${DateTime.fromISO(orderDate)
        .setLocale("de-DE")
        .toLocaleString()},${employeeId},${totalEuroPrice} €,${DateTime.fromISO(
        date,
      )
        .setLocale("de-DE")
        .setZone("Europe/Berlin")
        .toLocaleString(DateTime.DATETIME_FULL)},${
        cancelled ? "Ja" : "Nein"
      },${amount},${article.name},${
        article.category === "lunch"
          ? "Mittagessen"
          : article.category === "snack"
          ? "Snackbar"
          : "Getränke"
      },${
        bookingDate
          ? DateTime.fromISO(bookingDate)
              .setLocale("de-DE")
              .setZone("Europe/Berlin")
              .toLocaleString(DateTime.DATETIME_FULL)
          : "Noch nicht gebucht"
      }`;
    });
    return [header, ...rows].join("\n");
  }
}

export default OrderList;
