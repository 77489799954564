import { getArticleCategoryIcon } from "../../../lib/icon";
import { SnackbarArticle } from "../../../models/types";
import React, { FC, useState } from "react";
import { snackbarApi } from "../../../api/SnackbarApiClient";
import Modal from "@mittwald/flow-components/dist/components/Modal";
import { showSnackbar } from "@mittwald/flow-components/dist/components/Snackbar/lib";
import { Snackbar } from "@mittwald/flow-components/dist/components/Snackbar/Snackbar";
import { ModalContent } from "@mittwald/flow-components/dist/components/Modal/components/ModalContent/ModalContent";
import { ModalFooter } from "@mittwald/flow-components/dist/components/Modal/components/ModalFooter/ModalFooter";
import { SectionItem } from "@mittwald/flow-components/dist/components/Section/components/SectionItem";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { TextField } from "@mittwald/flow-components/dist/components/TextField/TextField";
import { useForm } from "@mittwald/flow-components/dist/components/Form/hooks/useForm/useForm";
import { EditInputs } from "../EditInputs";
import Form from "@mittwald/flow-components/dist/components/Form";
import { Select } from "@mittwald/flow-components/dist/components/Select/Select";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout/ColumnLayout";
import { SectionLayout } from "@mittwald/flow-components/dist/components/Section/components";
import Switch from "@mittwald/flow-components/dist/components/Switch/Switch";

export interface EditArticleModalProps {
  article: SnackbarArticle;
  onConfirm: () => void;
}
export const EditArticleModal: FC<EditArticleModalProps> = (
  props: EditArticleModalProps,
) => {
  const { article, onConfirm } = props;
  const [category, setCategory] = useState<"lunch" | "snack" | "drink">(
    article.category,
  );

  const visibilityController = useVisibilityController(false);

  const form = useForm<EditInputs>({
    defaultValues: {
      name: article.name,
      imageUrl: article.imageUrl ?? undefined,
      enabled: article.enabled,
      centPrice: article.centPrice,
      category: article.category,
      articleId: article.articleId,
    },
    reValidateMode: "onChange",
    mode: "onChange",
    onSubmit: async (values) => {
      const response = await snackbarApi.updateArticle.request({
        path: {
          articleId: article.articleId,
        },
        requestBody: {
          articleId: article.articleId,
          name: values.name,
          centPrice: values.centPrice,
          category: category,
          imageUrl: values.imageUrl,
          enabled: values.enabled,
        },
      });
      if (response.status === 200) {
        showSnackbar(
          <Snackbar
            text={{
              text: "Änderung erfolgreich gespeichert!",
            }}
          />,
        );
      } else {
        showSnackbar(
          <Snackbar
            error
            text={{
              text: "Änderungen konnten nicht gespeichert werden.",
            }}
          />,
        );
        return false;
      }
      onConfirm();
      visibilityController.hide();
    },
  });

  return (
    <Modal visibility={visibilityController} _size="l">
      <ModalContent
        description="articleDescription"
        headline={{
          id: "editArticle",
          values: {
            article: article.name,
          },
        }}
        headlineIcon={getArticleCategoryIcon(article.category)}
      >
        <Form controller={form}>
          <SectionLayout>
            <SectionItem
              headline={{ text: article.name }}
              headlineContent={
                <Switch
                  defaultChecked={true}
                  label="articleDisabled"
                  labelActive="articleEnabled"
                  name="enabled"
                  textRight={true}
                />
              }
            >
              <ColumnLayout medium={[1, 1]} small={[1]}>
                <TextField
                  label="name"
                  name="name"
                  rules={{ required: true }}
                />
                <TextField
                  label="articleId"
                  name="articleId"
                  disabled
                  rules={{ required: true }}
                />
              </ColumnLayout>
              {category === "lunch" ? (
                <Select.View
                  disabled
                  label="category"
                  options={[
                    { label: "lunch", value: "lunch" },
                    { label: "snack", value: "snack" },
                    { label: "drink", value: "drink" },
                  ]}
                  placeholder={{ text: "Wähle die Kategorie..." }}
                  onChange={setCategory}
                />
              ) : (
                <Select.View
                  label="category"
                  options={[
                    { label: "snack", value: "snack" },
                    { label: "drink", value: "drink" },
                  ]}
                  placeholder={{ text: "Wähle die Kategorie..." }}
                  onChange={setCategory}
                />
              )}
              <TextField
                label="centPrice"
                name="centPrice"
                rules={{
                  required: true,
                  validate: {
                    isNumber: (v?) => {
                      const regex = /^(0|[1-9]\d*)(\.\d+)?$/;
                      if (v == "" || v == undefined) {
                        return true;
                      }
                      return regex.test(v);
                    },
                  },
                  min: 1,
                  maxLength: 4,
                }}
              />
              <TextField
                label="imageUrl"
                name="imageUrl"
                rules={{
                  required: false,
                  minLength: 0,
                  validate: {
                    isUrl: (v?: string) => {
                      const regex = /(https):\/\/[^\s/$.?#].[^\s]*/;
                      if (v == "" || v == undefined) {
                        return true;
                      }
                      return regex.test(v);
                    },
                  },
                }}
                placeholder={{ text: "Bild-URL" }}
              />
            </SectionItem>
          </SectionLayout>
        </Form>
      </ModalContent>
      <ModalFooter
        primary={{
          text: "saveNow",
          action: { form },
        }}
        visibility={visibilityController}
      />
    </Modal>
  );
};
