import Page from "@mittwald/flow-renderer/dist/components/Page/Page";
import React, { FC, useEffect } from "react";
import { snackbarApi } from "../../api/SnackbarApiClient";
import { ResourceList } from "@mittwald/flow-components/dist/components/ResourceList/components/ResourceList/ResourceList";
import { Column } from "@mittwald/flow-components/dist/components/ResourceList/components/Column/Column";
import { getArticleCategoryIcon } from "../../lib/icon";
import { getArticleCategoryDefaultImage } from "../../lib/image";
import { SnackbarArticle } from "../../models/types";
import { useResourceList } from "@mittwald/flow-components/dist/components/ResourceList/hooks/useResourceList/useResourceList";
import { ResourceListColumnRenderer } from "@mittwald/flow-components/dist/components/ResourceList/hooks/useResourceList";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer/libs";
import { EditArticleModal } from "./modals/EditArticleModal";
import { faBarcode } from "@fortawesome/pro-regular-svg-icons/faBarcode";
import { filterFactory } from "@mittwald/flow-components/dist/components/ResourceList/hooks/useResourceList/filterFactory";
import { priceColumnRenderer } from "../../components/Article/priceColumnRenderer";
import { faShoppingBasket } from "@fortawesome/pro-regular-svg-icons/faShoppingBasket";
import { IconTools } from "@tabler/icons-react";
import { IllustratedMessage } from "@mittwald/flow-react-components/IllustratedMessage";
import Icon from "@mittwald/flow-react-components/Icon";
import Heading from "@mittwald/flow-react-components/Heading";
import Text from "@mittwald/flow-react-components/Text";

export const ArticleAdminOverview: FC = () => {
  const articleResource = snackbarApi.getAllArticles.getResource({
    query: { includeDisabled: true },
  });

  const response = articleResource.useWatchData();
  const articles = response.articles;

  useEffect(() => {
    articleResource.expire();
  }, []);

  const idColumnRenderer: ResourceListColumnRenderer<SnackbarArticle> = ({
    resource,
  }) => (
    <Column
      icon={faBarcode}
      text={{ text: resource.articleId }}
      tooltip={"articleId"}
    />
  );
  const showEditArticleModal = (article: SnackbarArticle): void => {
    showModal(
      "editArticleConfirmation",
      <EditArticleModal
        article={article}
        onConfirm={() => {
          articleResource.expire();
        }}
      />,
    );
  };

  const categoryColumnRenderer: ResourceListColumnRenderer<SnackbarArticle> = ({
    resource,
  }) => {
    const icon = getArticleCategoryIcon(resource.category);
    return (
      <Column
        icon={icon}
        text={{ id: resource.category }}
        tooltip={"category"}
      />
    );
  };

  const articleFilters = filterFactory<SnackbarArticle>()
    .fulltextFilter((article, query) => {
      return article.name.toLowerCase().includes(query.toLowerCase());
    })
    .boolFilter("lunch", (article) => {
      return article.category == "lunch";
    })
    .boolFilter("snack", (article) => {
      return article.category == "snack";
    })
    .boolFilter("drink", (article) => {
      return article.category == "drink";
    })
    .boolFilter("disabled", (article) => {
      return !article.enabled;
    })
    .boolFilter("enabled", (article) => {
      return article.enabled;
    })
    .get();

  const resourceList = useResourceList<SnackbarArticle>({
    resources: articles,
    bodyColumns: [
      {
        id: "articleId",
        renderer: idColumnRenderer,
      },
      {
        id: "category",
        renderer: categoryColumnRenderer,
      },
      {
        id: "centPrice",
        renderer: priceColumnRenderer,
      },
    ],
    mainActionMapper: (article) => () => {
      showEditArticleModal(article);
    },
    secondaryStatusIcons: [
      {
        mapper: (article) => {
          return {
            status: article.enabled ? "enabled" : "disabled",
            tooltip: article.enabled
              ? "Artikel ist buchbar"
              : "Artikel ist nicht buchbar",
            icon: faShoppingBasket,
          };
        },
      },
    ],
    filter: articleFilters,
    booleanFilters: ["lunch", "snack", "drink", "enabled", "disabled"],
  });

  return (
    <Page>
      {articles.length < 1 ? (
        <IllustratedMessage>
          <Icon>
            <IconTools />
          </Icon>
          <Heading>Keine Artikel gefunden</Heading>
          <Text>In dieser Kategorie gibt es momentan keine Artikel!</Text>
        </IllustratedMessage>
      ) : (
        <ResourceList
          {...resourceList}
          columnLayout={{
            body: [
              { key: "articleId" },
              { key: "category" },
              { key: "centPrice" },
            ],
          }}
          defaultView="tiles"
          imageUrl={(article) => {
            return {
              url: getArticleCategoryDefaultImage(article.category),
            };
          }}
        />
      )}
    </Page>
  );
};

export default ArticleAdminOverview;
