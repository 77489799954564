import React, { FC, useState } from "react";
import { SectionItem } from "@mittwald/flow-components/dist/components/Section/components/SectionItem";
import { H1 } from "@mittwald/flow-components/dist/components/H1/H1";
import TextField from "@mittwald/flow-components/dist/components/TextField/TextField";
import Page from "@mittwald/flow-renderer/dist/components/Page/Page";
import ActionBar from "@mittwald/flow-components/dist/components/ActionBar/ActionBar";
import Form from "@mittwald/flow-components/dist/components/Form/Form";
import { PasswordForgotInputs } from "./LoginInputs";
import { snackbarApi } from "../../api/SnackbarApiClient";
import { useForm } from "@mittwald/flow-components/dist/components/Form/hooks/useForm/useForm";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import TranslationProvider from "@mittwald/flow-components/dist/components/TranslationProvider";
import ColumnLayout from "@mittwald/flow-components/dist/components/ColumnLayout/ColumnLayout";
import Box from "@mittwald/flow-components/dist/components/Box";
import Text from "@mittwald/flow-components/dist/components/Text/Text";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink/TextLink";
import { useLinkBuilder } from "@mittwald/flow-lib/dist/hooks/useLinkBuilder";

export const ForgotPassword: FC = () => {
  const goto = useGotoLink();
  const linkTo = useLinkBuilder();
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const form = useForm<PasswordForgotInputs>({
    onSubmit: async (values) => {
      const response = await snackbarApi.forgotPassword.request({
        requestBody: {
          employeeId: values.employeeId,
          email: values.email,
        },
      });
      if (response.status == 200) {
        setShowSuccessScreen(true);
      } else {
        return false;
      }
    },
  });

  if (showSuccessScreen) {
    return (
      <TranslationProvider name="successForgotPassword" type="page">
        <ColumnLayout>
          <H1 i18n="title"></H1>
          <Box>
            <Text i18n="content"></Text>
          </Box>
          <TextLink action={linkTo("login")} i18n="loginLink" />
        </ColumnLayout>
      </TranslationProvider>
    );
  }

  return (
    <Page>
      <Form controller={form}>
        <SectionItem>
          <H1>Passwort vergessen?</H1>
          <TextField
            label={{ text: "Personalnummer" }}
            name="employeeId"
            rules={{ required: true, minLength: 4, maxLength: 4 }}
          />
          <TextField
            label={{ text: "E-Mail-Adresse" }}
            name="email"
            rules={{ required: true }}
          />
          <ActionBar
            primary={{
              text: "passwordReset",
              action: { form },
            }}
            secondary={{
              text: "goback",
              action: () => goto("login"),
            }}
          />
        </SectionItem>
      </Form>
    </Page>
  );
};

export default ForgotPassword;
