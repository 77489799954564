import { SnackbarMenu } from "../../models/types";
import React from "react";
import { ResourceListColumnRenderer } from "@mittwald/flow-components/dist/components/ResourceList";
import Text from "@mittwald/flow-components/dist/components/Text/Text";
import { DateTime } from "luxon";

export const menuDateColumnRenderer: ResourceListColumnRenderer<
  SnackbarMenu
> = ({ resource }) => {
  const date = DateTime.fromISO(resource.date).toFormat("dd.MM.yyyy");
  const day = DateTime.fromISO(resource.date).weekdayLong ?? "";

  return (
    <Text
      i18n={{
        id: "menu.dateColumn",
        values: {
          day: day,
          date: date,
        },
      }}
    />
  );
};
