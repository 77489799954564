
import {sessionStore} from "../login/session";
import {snackbarApi} from "../api/SnackbarApiClient";
import { useEffect } from "react";

export const useAutoLogoutOnExpiredSession = (): void => {
    const accessToken = sessionStore.accessToken;

    const checkApiToken = snackbarApi.verifyToken
        .getResource( {requestBody:{jwt: accessToken}} )
        .useWatchData({
            optional: true,
        });

    const sessionIsExpired = !!accessToken && !checkApiToken;

    useEffect(() => {
        if (sessionIsExpired) {
            sessionStore.logout();
        }
    }, [sessionIsExpired]);
};

export default useAutoLogoutOnExpiredSession;