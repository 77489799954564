import Box from "@mittwald/flow-components/dist/components/Box/Box";
import H1 from "@mittwald/flow-components/dist/components/H1/H1";
import Text from "@mittwald/flow-components/dist/components/Text/Text";
import React, { FC } from "react";

export const LoginSidebar: FC = () => (
  <Box _flexDirection="column" _itemGap="m">
    <H1 _color="inverse">
      <Text i18n="frontside.sidebar.title" />
    </H1>
    <Text _color="inverse" strong>
      <Text i18n="frontside.sidebar.subtitle" />
    </Text>
  </Box>
);

export default LoginSidebar;
