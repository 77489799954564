import { ResourceListColumnRenderer } from "@mittwald/flow-components/dist/components/ResourceList";
import { SnackbarArticle } from "../../models/types";
import React from "react";
import Text from "@mittwald/flow-components/dist/components/Text/Text";

export const nameColumnRenderer: ResourceListColumnRenderer<
  SnackbarArticle
> = ({ resource }) => {
  return (
    <Text
      i18n={{
        text: resource.name,
      }}
    />
  );
};
