import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { faUtensils } from "@fortawesome/pro-regular-svg-icons/faUtensils";
import { faCookieBite } from "@fortawesome/pro-regular-svg-icons/faCookieBite";
import { faWineBottle } from "@fortawesome/pro-regular-svg-icons/faWineBottle";

export const getArticleCategoryIcon = (
  category: "lunch" | "snack" | "drink" | undefined,
): IconDefinition => {
  switch (category) {
    case "lunch":
      return faUtensils;
    case "snack":
      return faCookieBite;
    case "drink":
      return faWineBottle;
    default:
      return faUtensils;
  }
};
