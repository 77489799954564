import React, { FC } from "react";
import { Modal } from "@mittwald/flow-components/dist/components/Modal/Modal";
import {
  useVisibilityController,
  VisibilityController,
} from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { ModalContent } from "@mittwald/flow-components/dist/components/Modal/components/ModalContent/ModalContent";
import { ModalFooter } from "@mittwald/flow-components/dist/components/Modal/components/ModalFooter/ModalFooter";
import { snackbarApi } from "../../../api/SnackbarApiClient";
import { showSnackbar } from "@mittwald/flow-components/dist/components/Snackbar/lib";
import { Snackbar } from "@mittwald/flow-components/dist/components/Snackbar/Snackbar";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { faUserXmark } from "@fortawesome/pro-regular-svg-icons/faUserXmark";
export const DeleteUserConfirmationModal: FC<{
  employeeId: string;
  parentModalVisibility: VisibilityController;
}> = (props) => {
  const { employeeId, parentModalVisibility } = props;
  const visibilityController = useVisibilityController(false);

  const deleteUser = async (): Promise<boolean> => {
    const response = await snackbarApi.deleteEmployee.request({
      requestBody: {
        employeeId: employeeId,
      },
    });
    if (response.status === 200) {
      showSnackbar(
        <Snackbar
          text={{
            text: `Personalnummer ${employeeId} erfolgreich gelöscht!`,
          }}
        />,
      );
    } else {
      showSnackbar(
        <Snackbar
          error
          text={{
            text: "Die Löschung hat nicht geklappt!",
          }}
        />,
      );
      visibilityController.hide();
      return false;
    }
    visibilityController.hide();
    return true;
  };

  return (
    <Modal _size={"s"} visibility={visibilityController}>
      <ModalContent
        description="deleteUserConfirmationDescription"
        headline={{
          id: "deleteUserConfirmationHeadline",
          values: {
            user: employeeId,
          },
        }}
        headlineIcon={faUserXmark}
      />
      <ModalFooter
        primary={{
          text: "deleteNow",
          destructive: true,
          action: async () => {
            visibilityController.hide();
            parentModalVisibility.hide();
            await deleteUser();
          },
          icon: faTrash,
        }}
        visibility={visibilityController}
      />
    </Modal>
  );
};

export default DeleteUserConfirmationModal;
