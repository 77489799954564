import { ResourceListColumnRenderer } from "@mittwald/flow-components/dist/components/ResourceList";
import { SnackbarOrder } from "../../models/types";
import { DateTime } from "luxon";
import { Column } from "@mittwald/flow-components/dist/components/ResourceList/components/Column/Column";
import { faCalendarDay } from "@fortawesome/pro-regular-svg-icons/faCalendarDay";
import { DateTimeText } from "@mittwald/flow-components/dist/components/DateTimeText/DateTimeText";
import React from "react";
import { faCancel } from "@fortawesome/pro-regular-svg-icons/faCancel";

export const orderDateColumnRenderer: ResourceListColumnRenderer<
  SnackbarOrder
> = ({ resource }) => {
  const date = resource.orderDate
    ? DateTime.fromISO(resource.orderDate)
    : undefined;
  const tooltip = date
    ? `Gebucht für den ${date.toFormat("dd.MM.yyyy")}`
    : undefined;
  if (resource.cancelled) {
    return (
      <Column
        icon={faCancel}
        text={<DateTimeText format="date" value={resource.orderDate} />}
        tooltip={{ text: "Buchung wurde storniert" }}
      />
    );
  }
  return (
    <Column
      icon={faCalendarDay}
      text={<DateTimeText format="date" value={resource.orderDate} />}
      tooltip={{ text: tooltip }}
    />
  );
};
