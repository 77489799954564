import {
  Box,
  LoadingView,
} from "@mittwald/flow-components/dist/components/Box";
import { DefaultErrorView } from "@mittwald/flow-components/dist/components/ErrorBoundary/components";
import { SnackbarContainer } from "@mittwald/flow-components/dist/components/SnackbarContainer";
import { useCompactMode } from "@mittwald/flow-components/dist/hooks/useCompactMode";
import useScrollToTop from "@mittwald/flow-lib/dist/hooks/useScrollToTop";
import { RendererProps } from "@mittwald/flow-lib/dist/pages/RendererProps";
import useWebsiteTinting from "@mittwald/flow-styles/dist/hooks/useWebsiteTinting";
import { color } from "@mittwald/flow-styles/dist/tokens/ts/variables";
import React, { FC } from "react";
import { CallToAction } from "./components/CallToAction";
import FooterLinks from "./components/FooterLinks";
import Sidebar from "./components/Sidebar";
import GlobalStyle from "./GlobalStyle";
import { FrontsideRendererProps, FrontsideViewSlots } from "./types";
import { Header } from "@mittwald/flow-renderer/dist/components/Header";
import { PlainTopPageRenderer } from "@mittwald/flow-renderer/dist/components/PlainTopPageRenderer";
import { FloatingButton } from "@mittwald/flow-renderer/dist/components/FloatingButton";

export const FrontsideRenderer: FC<
  RendererProps<FrontsideViewSlots, FrontsideRendererProps>
> = () => {
  const compactMode = useCompactMode();
  useWebsiteTinting(color.brand.primary.default);
  useScrollToTop(window);

  if (compactMode) {
    return (
      <Box _bgColor="white" _flexDirection="column" _minH="100vh">
        <GlobalStyle />
        <Header _bgColor="brand-primary" _height={58} _px="m">
          <PlainTopPageRenderer view="callToAction" />
        </Header>
        <Box _my="xl" _px="m">
          <PlainTopPageRenderer errorView={DefaultErrorView} view="main" />
        </Box>
        <FooterLinks />
        <SnackbarContainer
          _bottom={0}
          _left={0}
          _pb="m"
          _position="fixed"
          _px="m"
          _right={0}
        />
      </Box>
    );
  }

  return (
    <Box _flexDirection="row" _minH="100vh">
      <GlobalStyle />
      <Sidebar _bottom={0} _left={0} _position="fixed" _top={0} _width={480}>
        <PlainTopPageRenderer
          enableContentInheritance
          errorView={DefaultErrorView}
          key="sidebar"
          loadingView={<LoadingView />}
          view="sidebar"
        />
      </Sidebar>
      <Box _flexBasis={420} _noShrink />
      <Box
        _alignItems="center"
        _flexDirection="column"
        _grow
        _justifyContent="center"
      >
        <Box
          _flexDirection="column"
          _grow
          _justifyContent="center"
          _mb="xl"
          _p="xl"
          _pt="l"
          _width={500}
        >
          <PlainTopPageRenderer
            errorView={DefaultErrorView}
            loadingView={<LoadingView />}
            view="main"
          />
        </Box>
        <FooterLinks />
      </Box>
      <CallToAction>
        <PlainTopPageRenderer view="callToAction" />
      </CallToAction>
      <SnackbarContainer _bottom={0} _maxW={240} _p="xl" _position="fixed" />
      <FloatingButton />
    </Box>
  );
};

export default FrontsideRenderer;
