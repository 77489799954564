import {ErrorBoundaryComponentProps} from "@mittwald/flow-components/dist/components/ErrorBoundary";
import React, {FC} from "react";
import NoAccessError from "@mittwald/flow-lib/dist/access/NoAccessError";
import {NoAccessResourceLoadingError} from "@mittwald/flow-lib/dist/resources/ResourceLoadingError";
import NoAccess from "./NoAccess";
import {DefaultErrorView} from "@mittwald/flow-components/dist/components/ErrorBoundary/components";

export const ErrorView: FC<ErrorBoundaryComponentProps> = (props) => {
    const { error } = props;
    if (
        error instanceof NoAccessError ||
        error instanceof NoAccessResourceLoadingError
    ) {
        return <NoAccess />;
    }

    return <DefaultErrorView error={error} />;
};

export default ErrorView;
