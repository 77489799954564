import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import { appPages } from "./pages/appPages";
import { PlainTopPageRenderer } from "@mittwald/flow-renderer/dist/components/PlainTopPageRenderer";
import { loginPages } from "./pages/loginPages";
import { lazy } from "react";
import { accountingPages } from "./pages/accountingPages";
import { menuPages } from "./pages/menuPages";

export const pages = PageTree.build(PlainTopPageRenderer, {
  "/": {
    views: {
      main: lazy(() => import("./Start")),
    },
    linkId: "start",
  },
})
  .extend(appPages)
  .extend(loginPages)
  .extend(accountingPages)
  .extend(menuPages);
