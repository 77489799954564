import React, { FC } from "react";
import { PageMainSection } from "@mittwald/flow-renderer/dist/components/PageMainSection";
import Section from "@mittwald/flow-react-components/Section";
import { DateTime } from "luxon";
import { SnackbarMenu } from "../../../models/types";
import { MenuResourceList } from "./MenuResourceList";
import { getBookings } from "../../../api/SnackbarApiClient";
import { ApiOperationResource } from "@mittwald/flow-lib/dist/resources/ApiOperationResource";
import { Header } from "@mittwald/flow-react-components/Header";
import { Heading } from "@mittwald/flow-react-components/Heading";
import { StatusBadge } from "@mittwald/flow-react-components/StatusBadge";

interface LunchDayViewProps {
  menus: SnackbarMenu[];
  bookingsResource: ApiOperationResource<typeof getBookings>;
}

export const LunchDayView: FC<LunchDayViewProps> = (props) => {
  const { menus, bookingsResource } = props;
  const bookedMenus = bookingsResource.useWatchData({
    throwOnError: false,
  })?.bookings;

  const firstMenu = menus[0];

  if (!firstMenu || !firstMenu.active) return null;

  const isABookingForThisDay = bookedMenus?.some((booking) => {
    return booking.bookedDate === firstMenu.date;
  });

  return (
    <PageMainSection>
      <Section>
        <Header style={{ flexWrap: "wrap", flexDirection: "unset" }}>
          <Heading level={3}>
            {DateTime.fromISO(firstMenu.date).weekdayLong}, der{" "}
            {DateTime.fromISO(firstMenu.date).toFormat("dd.MM.yyyy")}
          </Heading>
          {isABookingForThisDay ? (
            <StatusBadge status="success">Angemeldet</StatusBadge>
          ) : (
            <StatusBadge status="danger">Nicht angemeldet</StatusBadge>
          )}
        </Header>
        <MenuResourceList
          menus={menus}
          onMenuSuccessfullyBooked={() => {
            bookingsResource.expire();
          }}
        />
      </Section>
    </PageMainSection>
  );
};
