import React, { FC } from "react";
import { Banner } from "@mittwald/flow-components/dist/components/Banner/Banner";
import {
  OrderLimitResponse,
  OrderLimitStateResponse,
} from "../hooks/useOrderLimit";

interface Props {
  orderLimit: OrderLimitResponse;
}

export const OrderLimitReachedBanner: FC<Props> = ({ orderLimit }) => {
  if (orderLimit.state === OrderLimitStateResponse.INFO) {
    return (
      <Banner
        _mb="m"
        description={{
          id: "personalOrderLimitReached15BannerDescription",
          values: {
            moneySpent: orderLimit.moneySpent / 100,
            orderLimit: orderLimit.orderLimit,
          },
        }}
        headline="personalOrderLimitReached15Banner"
        warning={true}
      />
    );
  } else if (orderLimit.state === OrderLimitStateResponse.WARNING) {
    return (
      <Banner
        _mb="m"
        description={{
          id: "personalOrderLimitReached5BannerDescription",
          values: {
            moneySpent: orderLimit.moneySpent / 100,
            orderLimit: orderLimit.orderLimit,
          },
        }}
        error={true}
        headline="personalOrderLimitReached5Banner"
      />
    );
  } else if (orderLimit.state === OrderLimitStateResponse.REACHED) {
    return (
      <Banner
        _mb="m"
        description={{
          id: "personalOrderLimitReachedBannerDescription",
          values: {
            moneySpent: orderLimit.moneySpent / 100,
            orderLimit: orderLimit.orderLimit,
          },
        }}
        error={true}
        headline="personalOrderLimitReachedBanner"
      />
    );
  } else {
    return <></>;
  }
};

export default OrderLimitReachedBanner;
