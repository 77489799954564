import { snackbarApi } from "../api/SnackbarApiClient";

export enum OrderLimitStateResponse {
  ALL_GOOD = "ALL_GOOD",
  INFO = "INFO",
  WARNING = "WARNING",
  REACHED = "REACHED",
}

export interface OrderLimitResponse {
  moneySpent: number;
  orderLimit: number;
  state: OrderLimitStateResponse;
}

export const useOrderLimit = (): OrderLimitResponse => {
  const orderLimitResponse = snackbarApi.getUserOrderLimit
    .getResource({})
    .useWatchData();
  const orderLimit = orderLimitResponse.orderLimit || -1;
  const moneySpent = orderLimitResponse.moneySpent || 0;

  let state = OrderLimitStateResponse.ALL_GOOD;

  if (orderLimit != -1) {
    if (
      moneySpent / 100 > orderLimit - orderLimit * 0.15 &&
      moneySpent / 100 < orderLimit - orderLimit * 0.05
    ) {
      // 85% REACHED
      state = OrderLimitStateResponse.INFO;
    } else if (
      moneySpent / 100 > orderLimit - orderLimit * 0.05 &&
      moneySpent / 100 < orderLimit
    ) {
      // 95% REACHED
      state = OrderLimitStateResponse.WARNING;
    } else if (moneySpent / 100 >= orderLimit) {
      // 100% REACHED / NO BOOKINGS MORE POSSIBLE
      state = OrderLimitStateResponse.REACHED;
    }
  }

  return {
    moneySpent,
    orderLimit,
    state,
  };
};
