import H1 from "@mittwald/flow-components/dist/components/H1/H1";
import Page from "@mittwald/flow-renderer/dist/components/Page/Page";
import React, { FC, useEffect, useState } from "react";
import { snackbarApi } from "../../api/SnackbarApiClient";
import { RedirectToLink } from "@mittwald/flow-lib/dist/components/RedirectToLink";
import { useQueryParams } from "@mittwald/flow-lib/dist/hooks/useQueryParams";
import ColumnLayout from "@mittwald/flow-components/dist/components/ColumnLayout/ColumnLayout";
import { Box } from "@mittwald/flow-components/dist/components/Box/Box";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink/TextLink";
import { Text } from "@mittwald/flow-components/dist/components/Text/Text";
import { useLinkBuilder } from "@mittwald/flow-lib/dist/hooks/useLinkBuilder";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import Icon from "@mittwald/flow-components/dist/components/Icon";

export const EmailVerify: FC = () => {
  const linkTo = useLinkBuilder();
  const goto = useGotoLink();
  const { token } = useQueryParams(["token"]);

  if (token == undefined) {
    return <RedirectToLink linkId="login" />;
  }

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    async function verifyToken(): Promise<void> {
      const response = await snackbarApi.verify.request({
        requestBody: {
          token,
        },
      });
      if (response.status == 200) {
        setSuccess(true);
      }
      if (response.status >= 400 && response.status < 500) {
        goto("login");
      }
    }
    void verifyToken();
  }, []);

  return (
    <Page>
      {success ? (
        <ColumnLayout>
          <Icon _size="xl" _color="brand-primary" icon={faCheck} />
          <H1 i18n="title" />
          <Box>
            <Text i18n="description" />
          </Box>
          <TextLink action={linkTo("login")} i18n="loginLink" />
        </ColumnLayout>
      ) : (
        <ColumnLayout>
          <H1 i18n="error.title" />
          <Box>
            <Text i18n="error.description" />
          </Box>
          <TextLink action={linkTo("register")} i18n="error.registerLink" />
        </ColumnLayout>
      )}
    </Page>
  );
};

export default EmailVerify;
