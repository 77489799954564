import { getArticleCategoryIcon } from "../../../lib/icon";
import { SnackbarOrder } from "../../../models/types";
import React, { FC } from "react";
import { Modal } from "@mittwald/flow-components/dist/components/Modal/Modal";
import { ModalContent } from "@mittwald/flow-components/dist/components/Modal/components/ModalContent/ModalContent";
import { ModalFooter } from "@mittwald/flow-components/dist/components/Modal/components/ModalFooter/ModalFooter";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout/ColumnLayout";
import StaticInformation from "@mittwald/flow-components/dist/components/StaticInformation";
import MoneyValue from "@mittwald/flow-components/dist/components/MoneyValue/MoneyValue";
import { DateTimeText } from "@mittwald/flow-components/dist/components/DateTimeText/DateTimeText";
import { SectionItem } from "@mittwald/flow-components/dist/components/Section/components/SectionItem";
import { Pill } from "@mittwald/flow-components/dist/components/Pill";
import { DateTime } from "luxon";

export const OrderDetailModal: FC<{ order: SnackbarOrder }> = (props) => {
  const { order } = props;
  const visibilityController = useVisibilityController(false);
  const totalCentPrice = order.totalCentPrice;

  const orderBookingDate =
    order.bookingDate != undefined
      ? DateTime.fromISO(order.bookingDate).toFormat("dd.MM.yyyy")
      : "";
  const pill: FC = () => {
    if (order.cancelled) {
      return (
        <Pill
          error
          label={{
            id: "orderCancelled",
          }}
        />
      );
    }
    return (
      <Pill
        label={{
          id: "orderBookedDate",
          values: { date: orderBookingDate },
        }}
        success
      />
    );
  };
  return (
    <Modal visibility={visibilityController}>
      <ModalContent
        description="orderDetailModalDescription"
        headline={{
          id: "orderDetailModalHeadline",
        }}
        headlineIcon={getArticleCategoryIcon(order.article.category)}
      >
        <SectionItem
          headlineContent={
            (order.bookingDate != undefined || order.cancelled) && pill(order)
          }
        >
          <ColumnLayout large={[1, 1]} medium={[1, 1]} small={[1]}>
            <StaticInformation
              copyContent={order.employeeId}
              label="employeeIdLabel"
              text={{ text: order.employeeId }}
            />
            <StaticInformation
              label="articleNameLabel"
              text={{ text: order.article.name }}
            />
            <StaticInformation label="orderCreatedDateLabel">
              <DateTimeText value={order.date} />
            </StaticInformation>
            <StaticInformation label="orderDateLabel">
              <DateTimeText value={order.orderDate} />
            </StaticInformation>
            <StaticInformation
              label="orderAmountLabel"
              text={{
                id: "orderAmount",
                values: {
                  amount: order.amount,
                },
              }}
            />
            <StaticInformation label="centPriceLabel">
              <MoneyValue value={totalCentPrice / 100} />
            </StaticInformation>
          </ColumnLayout>
        </SectionItem>
      </ModalContent>
      <ModalFooter visibility={visibilityController} />
    </Modal>
  );
};
