import React, { FC } from "react";
import { Modal } from "@mittwald/flow-components/dist/components/Modal/Modal";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import Form from "@mittwald/flow-components/dist/components/Form/Form";
import { ModalContent } from "@mittwald/flow-components/dist/components/Modal/components/ModalContent/ModalContent";
import { SectionItem } from "@mittwald/flow-components/dist/components/Section/components/SectionItem";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout/ColumnLayout";
import { ModalFooter } from "@mittwald/flow-components/dist/components/Modal/components/ModalFooter/ModalFooter";
import { useForm } from "@mittwald/flow-components/dist/components/Form/hooks/useForm/useForm";
import { snackbarApi } from "../../../api/SnackbarApiClient";
import { showSnackbar } from "@mittwald/flow-components/dist/components/Snackbar/lib";
import { Snackbar } from "@mittwald/flow-components/dist/components/Snackbar/Snackbar";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import { Switch } from "@mittwald/flow-components/dist/components/Switch/Switch";
import { SnackbarUser } from "../../../models/types";
import { StaticInformation } from "@mittwald/flow-components/dist/components/StaticInformation/StaticInformation";
import { faTrash } from "@fortawesome/pro-regular-svg-icons/faTrash";
import { TextLink } from "@mittwald/flow-components/dist/components/TextLink/TextLink";
import { TextWithIconLayout } from "@mittwald/flow-components/dist/components/TextWithIconLayout";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer/libs";
import DeleteUserConfirmationModal from "./DeleteUserConfirmationModal";
import { SectionLayout } from "@mittwald/flow-components/dist/components/Section/components";

export interface ProfileEditModalProps {
  user: SnackbarUser;
  enabled: boolean;
  onSuccessfulSubmit: () => void;
}
export const EditUserModal: FC<ProfileEditModalProps> = (
  props: ProfileEditModalProps,
) => {
  const { user, enabled, onSuccessfulSubmit } = props;
  const visibilityController = useVisibilityController(false);

  const form = useForm<{ user: SnackbarUser; enabled: boolean }>({
    defaultValues: { user: user, enabled: enabled },
    onSubmit: async (values) => {
      const response = await snackbarApi.disableEmployee.request({
        requestBody: {
          employeeId: user.employeeId,
          enabled: values.enabled,
        },
      });
      if (response.status === 200) {
        showSnackbar(
          <Snackbar
            text={{
              text: "Personalnummer erfolgreich geändert!",
            }}
          />,
        );
        onSuccessfulSubmit();
      } else {
        showSnackbar(
          <Snackbar
            error
            text={{
              text: "Die Änderungen haben nicht geklappt!",
            }}
          />,
        );
        visibilityController.hide();
        return false;
      }
      visibilityController.hide();
    },
  });

  return (
    <Modal visibility={visibilityController}>
      <ModalContent
        description="profileEditDescription"
        headline={{
          id: "employeeIdHeadline",
          values: {
            employeeId: user.employeeId,
          },
        }}
        headlineIcon={faEnvelope}
      >
        <Form controller={form}>
          <SectionLayout>
            <SectionItem>
              <ColumnLayout medium={[1, 1, 1]}>
                <StaticInformation
                  label="employeeId"
                  text={{ text: user.employeeId }}
                />
                <StaticInformation label="email" text={{ text: user.email }} />
                <Switch
                  defaultChecked={true}
                  label="employeeDisabled"
                  labelActive="employeeEnabled"
                  name="enabled"
                  textRight={true}
                  value={user.enabled}
                />
              </ColumnLayout>
            </SectionItem>
            <SectionItem>
              <TextWithIconLayout icon={faTrash}>
                <TextLink
                  action={() => {
                    showModal(
                      "deleteUserConfirmationModal",
                      <DeleteUserConfirmationModal
                        employeeId={user.employeeId}
                        parentModalVisibility={visibilityController}
                      />,
                    );
                  }}
                  destructive={true}
                  i18n={"deleteUser"}
                  warning={true}
                />
              </TextWithIconLayout>
            </SectionItem>
          </SectionLayout>
        </Form>
      </ModalContent>
      <ModalFooter
        primary={{
          text: "saveNow",
          action: { form },
          ok: true,
        }}
        visibility={visibilityController}
      />
    </Modal>
  );
};

export default EditUserModal;
