import React, { FC, useState } from "react";
import Page from "@mittwald/flow-renderer/dist/components/Page/Page";
import { useQueryToken } from "../../hooks/useQueryToken";
import { SectionLayout } from "@mittwald/flow-components/dist/components/Section/components";
import { useForm } from "@mittwald/flow-components/dist/components/Form/hooks/useForm/useForm";
import { MenuSection } from "./components/MenuSection";
import { snackbarApi } from "../../api/SnackbarApiClient";
import { DateTime } from "luxon";
import ExternalNoAccess from "../../components/Errors/ExternalNoAccess";
import IllustratedMessage from "@mittwald/flow-react-components/IllustratedMessage";
import Icon from "@mittwald/flow-react-components/Icon";
import Heading from "@mittwald/flow-react-components/Heading";
import Text from "@mittwald/flow-react-components/Text";
import { IconChecks } from "@tabler/icons-react";
import InlineAlert from "@mittwald/flow-react-components/InlineAlert";
import Content from "@mittwald/flow-react-components/Content";

interface ExternalMenuDashboardInputs {
  mondayMeat: string;
  mondayVeggie: string;
  mondayNoLunch: boolean;
  wednesdayMeat: string;
  wednesdayVeggie: string;
  wednesdayNoLunch: boolean;
}
export const externalMenuDashboard: FC = () => {
  const token = useQueryToken();
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);
  const form = useForm<ExternalMenuDashboardInputs>({
    onSubmit: async (values) => {
      const createdMenu = await snackbarApi.createMenu.request({
        requestBody: {
          token: token,
          mondayMeat: values.mondayMeat,
          mondayVeggie: values.mondayVeggie,
          wednesdayMeat: values.wednesdayMeat,
          wednesdayVeggie: values.wednesdayVeggie,
          mondayActive: values.mondayNoLunch,
          wednesdayActive: values.wednesdayNoLunch,
        },
      });

      if (createdMenu.status === 201) {
        setShowSuccessScreen(true);
      }
    },
  });

  if (token === undefined) {
    return (
      <Page>
        <SectionLayout>
          <ExternalNoAccess />
        </SectionLayout>
      </Page>
    );
  }
  const validatedToken = snackbarApi.getMenuForAccesstokenValidation
    .getResource({ path: { token: token } })
    .useWatchData();

  if (showSuccessScreen) {
    return (
      <Page>
        <IllustratedMessage>
          <Icon>
            <IconChecks />
          </Icon>
          <Heading>Erfolg</Heading>
          <Text>Die Gerichte wurden erfolgreich eingetragen.</Text>
        </IllustratedMessage>
      </Page>
    );
  }

  const mondayDate = DateTime.fromISO(validatedToken.monday);
  const wednesdayDate = DateTime.fromISO(validatedToken.wednesday);

  return (
    <Page form={form}>
      <InlineAlert status="info">
        <Heading>Info zu Feiertagen</Heading>
        <Content>
          Da dieses System automatisiert arbeitet, würden wir euch bitten, an
          Feiertagen keinen Text für den entsprechenden Tag einzugeben und den
          Schalter "Mittagessen buchbar" auf "Mittagessen nicht buchbar"
          umzuschalten. Vielen Dank!
        </Content>
      </InlineAlert>
      <SectionLayout>
        <MenuSection date={mondayDate} weekdayKey={"monday"} />
        <MenuSection date={wednesdayDate} weekdayKey={"wednesday"} />
      </SectionLayout>
    </Page>
  );
};

export default externalMenuDashboard;
