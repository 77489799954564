import { snackbarApi } from "../api/SnackbarApiClient";

export const useCheckAdminPermission = (): boolean => {
  try {
    const response = snackbarApi.getUserRole.getResource({}).useWatchData();
    return response.role == "admin";
  } catch (err) {
    return false;
  }
};

export const useCheckAzubiPermission = (): boolean => {
  try {
    const response = snackbarApi.getUserRole.getResource({}).useWatchData();
    return response.role == "azubidienst" || response.role == "admin";
  } catch (err) {
    return false;
  }
};
export const useCheckAccountingPermission = (): boolean => {
  try {
    const response = snackbarApi.getUserRole
      .getResource({})
      .useWatchData({ optional: false });
    return response.role == "accounting";
  } catch (err) {
    return false;
  }
};
export const useCheckEmployeePermission = (): boolean => {
  try {
    const response = snackbarApi.getUserRole.getResource({}).useWatchData();
    return response.role == "employee";
  } catch (err) {
    return false;
  }
};
