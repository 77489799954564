import React, { FC } from "react";
import { IllustratedMessage } from "@mittwald/flow-react-components/IllustratedMessage";
import Icon from "@mittwald/flow-react-components/Icon";
import { IconHandStop } from "@tabler/icons-react";
import Heading from "@mittwald/flow-react-components/Heading";
import Text from "@mittwald/flow-react-components/Text";

export const ExternalNoAccess: FC = () => {
  return (
    <IllustratedMessage color="negative">
      <Icon>
        <IconHandStop />
      </Icon>
      <Heading>Ungültiger Link!</Heading>
      <Text>
        Der Link ist ungültig. Bitte öffne den neusten Link, den wir per E-Mail
        verschickt haben innerhalb der gesetzten Frist und stelle sicher, dass
        noch niemand anderes Gerichte für diesen Zeitraum eingetragen hat.
      </Text>
    </IllustratedMessage>
  );
};

export default ExternalNoAccess;
