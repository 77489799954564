import React, { FC, useEffect } from "react";
import { Page } from "@mittwald/flow-renderer/dist/components/Page";
import { useResourceList } from "@mittwald/flow-components/dist/components/ResourceList/hooks/useResourceList/useResourceList";
import { snackbarApi } from "../../api/SnackbarApiClient";
import { ResourceList } from "@mittwald/flow-components/dist/components/ResourceList/components/ResourceList/ResourceList";
import { SnackbarUser } from "../../models/types";
import { ResourceListColumnRenderer } from "@mittwald/flow-components/dist/components/ResourceList";
import { Column } from "@mittwald/flow-components/dist/components/ResourceList/components/Column/Column";
import { faUser } from "@fortawesome/pro-regular-svg-icons/faUser";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import { faLockKeyhole } from "@fortawesome/pro-regular-svg-icons/faLockKeyhole";
import EditUserModal from "./modals/EditUserModal";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer/libs";
import { filterFactory } from "@mittwald/flow-components/dist/components/ResourceList/hooks/useResourceList/filterFactory";

export const UserAdminOverview: FC = () => {
  const usersResource = snackbarApi.getAllUsers.getResource({});
  useEffect(() => {
    usersResource.expire();
  }, []);
  const userResponse = usersResource.useWatchData();
  const users = userResponse.employees;
  const nameColumnRenderer: ResourceListColumnRenderer<SnackbarUser> = ({
    resource,
  }) => {
    return (
      <Column
        icon={faUser}
        text={{
          text: resource.employeeId,
        }}
      />
    );
  };

  const emailColumnRenderer: ResourceListColumnRenderer<SnackbarUser> = ({
    resource,
  }) => {
    return <Column icon={faEnvelope} text={{ text: resource.email }} />;
  };
  const showProfileEditModal = (user: SnackbarUser): void => {
    showModal(
      "ProfileEditModal",
      <EditUserModal
        enabled={user.enabled}
        user={user}
        onSuccessfulSubmit={() => usersResource.expire()}
      />,
    );
  };

  const filter = filterFactory<SnackbarUser>()
    .fulltextFilter((user, searchText) => {
      return user.employeeId.toLowerCase().includes(searchText.toLowerCase());
    })
    .boolFilter("disabled", (user) => {
      return !user.enabled;
    })
    .boolFilter("enabled", (user) => {
      return user.enabled;
    })
    .get();

  const resourceList = useResourceList<SnackbarUser>({
    resources: users,
    nameColumn: nameColumnRenderer,
    filter: filter,
    booleanFilters: ["disabled", "enabled"],
    bodyColumns: [
      {
        id: "email",
        renderer: emailColumnRenderer,
      },
    ],
    mainActionMapper: (user) => () => {
      showProfileEditModal(user);
    },
    secondaryStatusIcons: [
      {
        icon: faLockKeyhole,
        mapper: (user) => {
          return {
            status: user.enabled ? "disabled" : "enabled",
            tooltip: user.enabled ? "Nutzer aktiv" : "Nutzer deaktiviert",
          };
        },
      },
    ],
  });

  return (
    <Page>
      <ResourceList
        {...resourceList}
        columnLayout={{ body: [{ key: "email" }] }}
        hideTilesView
      />
    </Page>
  );
};

export default UserAdminOverview;
