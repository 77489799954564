import "@mittwald/flow-pro-icons";
import "@mittwald/flow-react-components/styles";
import React, { FC } from "react";
import { createRoot } from "react-dom/client";
import history from "./history";
import { pages } from "./pages";
import defaultLanguage from "./i18n/de";
import { SnackbarApiClient } from "./api/SnackbarApiClient";
import { sessionStore } from "./login/session";
import { snackbarHttpClient } from "./api/clients/snackbar";
import { setDefaultAccessToken } from "./api/clients/setDefaultAccessToken";
import { setup } from "./setup";
import { AppBootstrap } from "@mittwald/flow-components/dist/components/AppBootstrap/AppBootstrap";
import "regenerator-runtime/runtime.js";
import { Toaster } from "react-hot-toast";

setup();

const token = sessionStore.accessToken;
if (token) {
  setDefaultAccessToken(token);
}

const client = new SnackbarApiClient(snackbarHttpClient);
SnackbarApiClient.setInstance(client);

const App: FC = () => (
  <>
    <AppBootstrap
      compactBreakpoint={1180}
      defaultLanguage={defaultLanguage}
      environment={import.meta.env.MODE}
      history={history}
      importLanguageFile={(language) => import(`./i18n/${language}.ts`)}
      name="snackbar"
      pageTree={pages}
      persistedStores={[["session", sessionStore]]}
    />
    <Toaster position="bottom-center" />
  </>
);

const container = document.getElementById("root");

if (container) {
  const root = createRoot(container);
  root.render(<App />);
}
