import React, { FC } from "react";
import { SectionItem } from "@mittwald/flow-components/dist/components/Section/components/SectionItem";
import { DateTime } from "luxon";
import { DateTimeText } from "@mittwald/flow-components/dist/components/DateTimeText/DateTimeText";
import Switch from "@mittwald/flow-components/dist/components/Switch/Switch";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout/ColumnLayout";
import { useFormContext } from "@mittwald/flow-components/dist/components/Form";
import { TextArea } from "@mittwald/flow-components/dist/components/TextArea";

interface MenuSectionProps {
  weekdayKey: "monday" | "wednesday";
  date: DateTime;
}
export const MenuSection: FC<MenuSectionProps> = (props) => {
  const { weekdayKey, date } = props;
  const form = useFormContext();

  const active = form.watch(weekdayKey + "NoLunch");
  return (
    <SectionItem
      headline={{
        text: (
          <DateTimeText
            format="date"
            translationKey={weekdayKey}
            value={date}
          />
        ),
      }}
      headlineContent={
        <Switch
          label="switch.noMenu"
          labelActive="switch.menu"
          name={weekdayKey + "NoLunch"}
          value={true}
        />
      }
    >
      {active && (
        <ColumnLayout medium={[1, 1]}>
          <TextArea
            _height={50}
            label="meat"
            name={weekdayKey + "Meat"}
            rules={{ required: true }}
          />
          <TextArea
            _height={50}
            label="veggie"
            name={weekdayKey + "Veggie"}
            rules={{ required: true }}
          />
        </ColumnLayout>
      )}
    </SectionItem>
  );
};
