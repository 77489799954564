import { PageTree } from "@mittwald/flow-lib/dist/pages/PageTree";
import { BacksideRenderer } from "@mittwald/flow-renderer/dist/components/BacksideRenderer";
import { faHistory } from "@fortawesome/pro-regular-svg-icons/faHistory";
import { History } from "./app/History";
import { faUtensils } from "@fortawesome/pro-regular-svg-icons/faUtensils";
import { RedirectToLink } from "@mittwald/flow-lib/dist/components/RedirectToLink";
import React from "react";
import HeaderNavigation from "./users/HeaderNavigation";
import sessionStore from "../login/session";
import { faCookieBite } from "@fortawesome/pro-regular-svg-icons/faCookieBite";
import { faToolbox } from "@fortawesome/pro-regular-svg-icons/faToolbox";
import ArticleAdminOverview from "./article/ArticleAdminOverview";
import { faWineBottle } from "@fortawesome/pro-regular-svg-icons/faWineBottle";
import { useCheckAzubiPermission } from "../hooks/useCheckAdminPermission";
import { CreateNewArticleModal } from "./article/modals/CreateNewArticleModal";
import { showModal } from "@mittwald/flow-components/dist/components/FunctionModalsContainer/libs";
import { SnackbarDashboard } from "./app/SnackbarDashboard";
import LunchDashboard from "./app/LunchDashboard";
import { useCheckAuthenticated } from "../hooks/useCheckAuthenticated";
import ErrorView from "../components/Errors/ErrorView";
import { MainBottomNavigation } from "../components/MainBottomNavigation";
import { faGear } from "@fortawesome/pro-regular-svg-icons/faGear";
import { MenuAdminOverview } from "./app/MenuAdminOverview";

export const appPages = PageTree.build(BacksideRenderer, {
  "/app": {
    views: {
      error: ErrorView,
      main: () => {
        if (sessionStore.accessToken) {
          return <RedirectToLink linkId="lunch" />;
        }
        sessionStore.logout();
        return <RedirectToLink linkId="login" />;
      },
      headerNavigation: () => <HeaderNavigation />,
      mainNavigation: MainBottomNavigation,
    },
    linkId: "app",
    accessCheck: useCheckAuthenticated,
  },
  "/app/lunch": {
    icon: faUtensils,
    linkId: "lunch",
    views: {
      main: () => {
        if (sessionStore.accessToken === undefined) {
          return <RedirectToLink linkId={"login"} />;
        }
        return <LunchDashboard category="lunch" />;
      },
    },
  },
  "/app/snackbar": {
    icon: faCookieBite,
    views: {
      main: () => {
        if (sessionStore.accessToken === undefined) {
          return <RedirectToLink linkId={"login"} />;
        }
        return <SnackbarDashboard category="snack" />;
      },
    },
  },
  "/app/drinks": {
    icon: faWineBottle,
    views: {
      main: () => {
        if (sessionStore.accessToken === undefined) {
          return <RedirectToLink linkId={"login"} />;
        }
        return <SnackbarDashboard category="drink" />;
      },
    },
  },
  "/app/history": {
    icon: faHistory,
    props: { hideInMenu: true },
    linkId: "history",
    views: {
      main: History,
    },
  },
  "/app/article": {
    icon: faToolbox,
    accessCheck: useCheckAzubiPermission,
    linkId: "editArticles",
    props: {
      ctaPages: [
        {
          action: {
            accessCheck: useCheckAzubiPermission,
            fn: () => {
              showModal("AddNewArticle", <CreateNewArticleModal />);
            },
          },
          title: "createNew",
          icon: faUtensils,
        },
      ],
      hideInMenu: true,
    },
    views: {
      error: ErrorView,
      main: ArticleAdminOverview,
    },
  },
  "/app/admin": {
    icon: faGear,
    linkId: "admin",
    props: {
      hideInMenu: true,
    },
    views: {
      error: ErrorView,
      main: MenuAdminOverview,
    },
  },
});
