import ActionBar from "@mittwald/flow-components/dist/components/ActionBar/ActionBar";
import Form from "@mittwald/flow-components/dist/components/Form/Form";
import H1 from "@mittwald/flow-components/dist/components/H1/H1";
import { SectionItem } from "@mittwald/flow-components/dist/components/Section/components/SectionItem";
import Page from "@mittwald/flow-renderer/dist/components/Page/Page";
import React, { FC, useEffect, useState } from "react";
import { LoginInputs } from "./LoginInputs";
import { snackbarApi } from "../../api/SnackbarApiClient";
import { useGotoLink } from "@mittwald/flow-lib/dist/hooks/useGotoLink";
import { useForm } from "@mittwald/flow-components/dist/components/Form/hooks/useForm/useForm";
import StaticInformation from "@mittwald/flow-components/dist/components/StaticInformation";
import { RedirectToLink } from "@mittwald/flow-lib/dist/components/RedirectToLink";
import { useQueryParams } from "@mittwald/flow-lib/dist/hooks/useQueryParams";
import TextField from "@mittwald/flow-components/dist/components/TextField/TextField";

export const ResetPassword: FC = () => {
  const goto = useGotoLink();
  const { token } = useQueryParams(["token"]);

  if (token == undefined) {
    return <RedirectToLink linkId="login" />;
  }

  const [employeeId, setEmployeeId] = useState<string>("");
  useEffect(() => {
    snackbarApi.getUserByPasswordToken
      .request({
        query: { token: token },
      })
      .then((response) => {
        if (response.status == 200) {
          setEmployeeId(response.content.employeeId);
        } else {
          goto("login");
        }
      })
      .catch(() => {
        goto("login");
      });
  }, []);

  const form = useForm<LoginInputs>({
    onSubmit: async (values) => {
      const response = await snackbarApi.resetPassword.request({
        requestBody: {
          employeeId: employeeId,
          token: token,
          newPassword: values.password,
        },
      });
      if (response.status == 200) {
        goto("login");
      }
    },
  });

  return (
    <Page>
      <Form controller={form}>
        <SectionItem>
          <H1>Neues Passwort setzen</H1>
          <StaticInformation label="employeeId" text={{ text: employeeId }} />
          <TextField
            label="password"
            name="password"
            rules={{ required: true, minLength: 12 }}
            type="passwordWithGenerator"
          />
          <ActionBar
            primary={{
              text: "setPassword",
              action: { form },
            }}
            secondary={{
              text: "cancel",
              action: () => goto("login"),
            }}
          />
        </SectionItem>
      </Form>
    </Page>
  );
};

export default ResetPassword;
