import React, { FC, useState } from "react";
import { ModalContent } from "@mittwald/flow-components/dist/components/Modal/components/ModalContent/ModalContent";
import { ModalFooter } from "@mittwald/flow-components/dist/components/Modal/components/ModalFooter/ModalFooter";
import { SectionItem } from "@mittwald/flow-components/dist/components/Section/components/SectionItem";
import Modal from "@mittwald/flow-components/dist/components/Modal";
import Form from "@mittwald/flow-components/dist/components/Form/Form";
import { useForm } from "@mittwald/flow-components/dist/components/Form/hooks/useForm/useForm";
import { snackbarApi } from "../../../api/SnackbarApiClient";
import { useVisibilityController } from "@mittwald/flow-components/dist/hooks/useVisibilityController";
import { showSnackbar } from "@mittwald/flow-components/dist/components/Snackbar/lib";
import { Snackbar } from "@mittwald/flow-components/dist/components/Snackbar/Snackbar";
import { NumberField } from "@mittwald/flow-components/dist/components/NumberField";
import { SectionLayout } from "@mittwald/flow-components/dist/components/Section/components";
import { ColumnLayout } from "@mittwald/flow-components/dist/components/ColumnLayout/ColumnLayout";
import { Select } from "@mittwald/flow-components/dist/components/Select/Select";

export interface DownloadAccountingSummary {
  year: number;
  month: number;
}

export const AccountingDownloadFilesModal: FC = () => {
  const confirmationModalVisibility = useVisibilityController(false);

  const [wantedMonth, setWantedMonth] = useState<number>(new Date().getMonth());

  const currentYear = new Date().getFullYear();

  const months = [
    { value: 1, label: "Januar" },
    { value: 2, label: "Februar" },
    { value: 3, label: "März" },
    { value: 4, label: "April" },
    { value: 5, label: "Mai" },
    { value: 6, label: "Juni" },
    { value: 7, label: "Juli" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "Oktober" },
    { value: 11, label: "November" },
    { value: 12, label: "Dezember" },
  ];

  const form = useForm<DownloadAccountingSummary>({
    onSubmit: async (values) => {
      const result = await snackbarApi.getAccountingSummary.request({
        path: {
          year: values.year,
          month: wantedMonth,
        },
      });
      if (result.status === 200) {
        if (result.content.csvData == "") {
          return showSnackbar(
            <Snackbar
              error
              text={{
                text: "Für diesen Monat liegen keine Daten vor.",
              }}
            />,
          );
        }

        showSnackbar(
          <Snackbar
            text={{
              text: "Download wird eingeleitet..",
            }}
          />,
        );

        const csvValue = result.content.csvData || "";

        const element = document.createElement("a");
        const file = new Blob([csvValue], { type: "text/csv" });
        element.href = URL.createObjectURL(file);
        element.download = `snaxus-${values.year}-${wantedMonth}.csv`;
        document.body.appendChild(element);
        element.click();
        URL.revokeObjectURL(element.href);
      }
      confirmationModalVisibility.hide();
    },
  });

  return (
    <Modal visibility={confirmationModalVisibility}>
      <ModalContent
        description="downloadAccountFilesDescription"
        headline="downloadAccountFilesHeader"
      >
        <Form controller={form}>
          <SectionLayout>
            <SectionItem>
              <ColumnLayout medium={[1, 1]}>
                <NumberField
                  label="year"
                  name="year"
                  rules={{
                    required: true,
                    maxLength: 4,
                    minLength: 4,
                    validate: {
                      amount: (v: number) => {
                        if (v >= 2023) {
                          return true;
                        }
                        {
                          return false;
                        }
                      },
                    },
                  }}
                  value={currentYear}
                />
                <Select.View
                  isSearchable
                  label="month"
                  options={months.map((month) => ({
                    value: month.value,
                    label: { text: month.label },
                  }))}
                  placeholder={{ text: "Wähle einen Monat..." }}
                  value={wantedMonth}
                  onChange={setWantedMonth}
                />
              </ColumnLayout>
            </SectionItem>
          </SectionLayout>
        </Form>
      </ModalContent>
      <ModalFooter
        primary={{
          text: "download",
          action: { form },
        }}
        visibility={confirmationModalVisibility}
      />
    </Modal>
  );
};

export default AccountingDownloadFilesModal;
